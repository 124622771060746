/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/no-unresolved */
import React from "react";
import loadable from "@loadable/component";

import hhrScreenshot from "assets/images/hhr-access-screenshot.jpeg";
import hhrScreenshotWebp from "assets/images/hhr-access-screenshot.jpeg?as=webp";
import milaWithDimentions from "assets/images/mila-with-dimensions.png";
import milaWithDimentionsWebp from "assets/images/mila-with-dimensions.png?as=webp";

const ImageLoader = loadable(() => import("../components/common/ImageLoader"));

const supportFaqStatic = {
  header: {
    subtitle: "FAQ",
    title: "Frequently asked questions",
    shortDescription: <p>Have a Q? Find your A below.</p>,
  },
  faqItems: [
    {
      heading: "General",
      content: [
        {
          title: "How do you pronounce “Mila”?",
          details: <p>Mila should be pronounced “Mee-la” :)</p>,
        },
        {
          title: "Where should I place my Mila?",
          details: (
            <>
              <p>
                <strong>For Mila Air:</strong>
                For optimal performance, please allow at least 11.8 inches (30
                cm) of clearance around the Mila Air unit.
              </p>
              <p>
                <strong>For Mila Air Mini:</strong>
                For optimal performance, please allow at least 9.8 inches (25
                cm) around the Mila Air Mini unit.
              </p>
              <p>
                This allows the units to have enough space to draw air from all
                four sides for effective filtration, releasing clean air through
                the top vent.
              </p>
            </>
          ),
        },
        {
          title: "Where can I find a user handbook/quick start guide?",
          details: (
            <>
              <p>
                <strong>Mila Air</strong>
              </p>
              <ul>
                <li>
                  <a
                    href="https://docs.milacares.com/quickstart-guide.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Quick Start Guide
                  </a>
                </li>
                <li>
                  <a
                    href="https://docs.milacares.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    User Manual
                  </a>
                </li>
              </ul>
              <p>
                <strong>Mila Air Mini</strong>
              </p>
              <ul>
                <li>
                  <a
                    href="https://docs.milacares.com/Mila%20Air%20Mini%20Manual.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    User Manual
                  </a>
                </li>
              </ul>
            </>
          ),
        },
        {
          title: "What voltage range does Mila operate on?",
          details: <p>Mila is compatible globally across 100-240v.</p>,
        },
        {
          title: "Does Mila require a WiFi connection to operate?",
          details: (
            <p>
              While Mila does not require WiFi, it’s a lot cooler if you use
              Mila with WiFi. 🙂 Some of Mila’s smartest functions require a
              Wifi connection (e.g., Using the Mila App), but Mila can operate
              in Manual Mode and Automagic Mode completely offline via the
              on-device controls.
            </p>
          ),
        },
        {
          title: "How can I use Mila with my Alexa or Google Home devices?",
          details: (
            <>
              <p>
                <strong>Alexa: </strong>
                Get started by enabling the{" "}
                <a
                  href="https://alexa.amazon.com/spa/index.html#skills/dp/B098XF1CL8/?ref=skill_dsk_skb_sr_0&qid=cf2b5a18-008c-4a43-abe3-0b448504abab"
                  target="_blank"
                  rel="noreferrer"
                >
                  Mila Skill
                </a>{" "}
                (you'll want to have your Amazon log-in handy). Follow the
                on-screen instructions to link your Mila(s) to Alexa. For more
                information, please see{" "}
                <a
                  href="https://help.milacares.com/#amazon-alexa"
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>
                .
              </p>
              <p>
                <strong>Google Home: </strong>
                <br />
                <ul style={{ paddingLeft: "20px" }}>
                  <li>
                    Open the Google Home app and ensure you’re logged into the
                    Google account connected to your Google Home devices.
                  </li>
                  <li>
                    Navigate to: Home > Settings > Google Assistant > All > Home
                    Control.
                  </li>
                  <li>
                    In the Home Control section, search for “Mila Smart Home”
                    and follow the prompts to link your Mila to Google Home.
                  </li>
                </ul>
              </p>
            </>
          ),
        },
        {
          title: "Where can I purchase Mila's from?",
          details: (
            <>
              <p>
                <strong>Mila Air</strong>
                <br />
                You can purchase Mila Air units directly from the Mila website{" "}
                <a href="http://milacares.com" target="_blank" rel="noreferrer">
                  here.
                </a>
                , and is available in the US, Canada, Australia, the UK and
                Singapore. They’re also available in the US at Amazon, Best Buy,
                Wayfair, and The Home Depot. Please note that on Amazon, only
                units sold by Mila USA Inc. are considered authorized purchases.
              </p>
              <p>
                <strong>Mila Air Mini</strong>
                <br />
                Air Mini units are available directly from the Mila website{" "}
                <a
                  href="https://airmini.milacares.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  here.
                </a>{" "}
                and is available in the US and Canada. If you’re interested in
                Air Mini outside of the US or Canada, please join our{" "}
                <a
                  href="https://milamarketing.typeform.com/preordermini"
                  target="_blank"
                  rel="noreferrer"
                >
                  Mila Air Mini Waitlist
                </a>{" "}
                for regional updates.
              </p>
            </>
          ),
        },
        {
          title: "What are Mila's dimensions?",
          details: (
            <>
              <ImageLoader
                className="faq-grid__media faq-dimention-img img-relative"
                style={{ maxWidth: `${460}px` }}
                placeholderAspectRatio={831 / 830}
                image={milaWithDimentions}
                webp={milaWithDimentionsWebp}
                alt="Mila`s dimensions"
              />
              <p>
                The Mila Air measures 11.8” x 11.8” x 15.5” and the Mila Air
                Mini measures 9.8” x 9.8” x 13.2”. Both units can be placed
                anywhere, but for optimal airflow, it’s recommended to have
                approximately 11.8” of clearance on all sides for the Mila Air
                and 9.8” for the Mila Air Mini.
              </p>
            </>
          ),
        },
        {
          title: "Can I use Mila without connecting to Wi-Fi?",
          details: (
            <p>
              Absolutely! Mila will still work to clear your air as intended
              without a Wi-Fi connection. However, Wi-Fi connectivity enables
              some of Mila’s smartest features such as air quality tracking,
              custom settings and control via the Mila app, and receiving all of
              Mila’s latest and greatest updates.
            </p>
          ),
        },
        {
          title:
            "Are there any special discounts for purchasing multiple Mila units?",
          details: (
            <p>
              Smart shopper you. 🙂 Check our website or better yet, subscribe
              to our newsletter to stay up to date on our latest deals and
              offers.
            </p>
          ),
        },
        {
          title: "Is Mila suitable for homes with pets?          ",
          details: (
            <>
              <p>
                Absolutely, Mila is pet-friendly! More than that, one of our
                interchangeable filters includes the fan (and pet) favorite,{" "}
                <a
                  href="https://milacares.com/filters/critter-cuddler"
                  target="_blank"
                  rel="noreferrer"
                >
                  Critter Cuddler.
                </a>{" "}
                Like each of our filters, the Critter Cuddler is purpose-built
                specifically for households with pets in mind. This filter
                includes a Mila Sock to catch any larger particulates, fur and
                dander, and also includes carbon that’s specially designed to
                filter out ammonia.
              </p>
              <p>P.S. Mila is also bird approved!</p>
            </>
          ),
        },
        {
          title: "How does Mila contribute to a sustainable environment?",
          details: (
            <p>
              Sustainability is at the heart of Mila. Our filters are designed
              to last longer, reducing waste. We also employ eco-friendly modes
              and sustainable practices in our manufacturing and packaging
              process. We’re always looking for ways to reduce our footprint and
              are currently looking for solutions that allow us to better
              recycle our filters, so if you have any other ideas, please reach
              out.
            </p>
          ),
        },
        {
          title: "What is the filtration rate of VOCs by Mila air purifiers?",
          details: (
            <p>
              Mila air purifiers are designed to effectively filter Volatile
              Organic Compounds (VOCs) from your indoor air. The specific
              filtration rate depends on both the type of filter used as well as
              the Mila unit you are using the filter with. Each filter has been
              rigorously tested to ensure optimal performance in removing VOCs
              from the air.
            </p>
          ),
        },
        {
          title: "Can Mila air purifiers filter out smells and odors?",
          details: (
            <p>
              Yes, Mila air purifiers can filter out many smells and odors.
              Humans can detect certain odors at very low concentrations,
              sometimes even below the thresholds detectable by VOC sensors. Our
              filters are proven to be effective against a wide range of common
              household odors.
            </p>
          ),
        },
        {
          title: "What about the off-gassing of VOCs and HCHO (Formeldehyde)?",
          details: (
            <p>
              VOCs and formaldehyde can off-gas, and this process can increase
              with enhanced ventilation from a purifier or fan. This is due to
              the increased air movement which can bring more VOCs into the
              filter range. Our filters are designed to capture these off-gassed
              VOCs effectively. The combination of different carbon blends and
              the specialized design of the honeycomb and center flow filters
              allows for effective adsorption of a wide spectrum of VOCs.
            </p>
          ),
        },
        {
          title:
            "Is there a limit to what Mila air purifiers can filter in terms of VOCs?",
          details: (
            <p>
              While our filters are highly effective in typical home settings,
              they are not designed to handle extreme situations like a toxic
              spill. The efficacy of our filters has been proven through
              rigorous testing, but they are intended for use in normal
              household environments.
            </p>
          ),
        },
        {
          title: "What are common sources of VOCs in a home?",
          details: (
            <p>
              VOCs, or Volatile Organic Compounds, are typically emitted from
              household products, new furniture, or fresh paint. Other VOCs
              include us (humans), Cooking, burning, and other in-house gas
              sources. They can cause respiratory tract irritation, headaches,
              and dizziness in the short term. Prolonged exposure can lead to
              more serious health issues like cancer and liver damage.
            </p>
          ),
        },
        {
          title: "How effective are Mila filters at trapping VOCs?",
          details: (
            <p>
              <a
                href="https://milacares.com/filters"
                target="_blank"
                rel="noreferrer"
              >
                Mila filters
              </a>
              , especially those with a granular activated carbon layer are
              primarily used for VOC filtering. They can become saturated more
              quickly than the HEPA filter, especially in environments with high
              levels of VOCs. The rate of saturation varies depending on the
              amount of VOCs in your home, so it's difficult to predict a
              universal replacement timeline. We recommend monitoring your air
              quality and replacing the carbon filter when you notice a decrease
              in odor reduction efficiency.
            </p>
          ),
        },
        {
          title:
            "What is the difference between the Mila Air and the Mila Air Mini?",
          details: (
            <>
              <p>
                The main differences between the Mila Air and the Mila Air Mini
                are size, filtration power, and some technical specifications:
              </p>
              <ol>
                <li>
                  Size: The Mila Air is larger at 11.8” x 11.8” x 15.5”, while
                  the Mila Air Mini is more compact at 9.8” x 9.8” x 13.2”. This
                  makes the Mini ideal for smaller spaces while still
                  maintaining powerful filtration capabilities.{" "}
                </li>
                <li>
                  Filtration Power: The Mila Air has a Clean Air Delivery Rate
                  (CADR) of up to 447 m3/h, making it suitable for larger rooms.
                  In comparison, the Mila Air Mini is designed for smaller
                  spaces with a CADR of up to 357 m3/h. Filter options also vary
                  between models, with Mila Air offering 7 filters and Mila Air
                  Mini offering 4 filters.{" "}
                </li>
                <li>
                  Sensors: The Mila Air is equipped with a comprehensive suite
                  of sensors, including PM1, PM2.5, PM10, CO2, CO, VOCs,
                  temperature, humidity, and a human presence sensor. Meanwhile,
                  the Mila Air Mini includes six key sensors: PM1, PM2.5, PM10,
                  temperature, humidity, and CO (carbon monoxide) sensors,
                  ensuring effective monitoring of indoor air quality.
                </li>
              </ol>
            </>
          ),
        },
        {
          title: "Can I read reviews?",
          details: (
            <p>
              Yes, you can read user reviews on the{" "}
              <a
                href="https://milacares.com/the-wall-of-love"
                target="_blank"
                rel="noreferrer"
              >
                Wall of Love
              </a>
              , where our users share their experiences and feedback on Mila
              products.
            </p>
          ),
        },

        {
          title: "How accurate is the VOC sensor in Mila Air?",
          details: (
            <p>
              The VOC sensors in Mila Air are designed to detect VOC levels with
              a reasonable degree of accuracy. However, it's important to
              understand that VOCs are a diverse group of chemicals, and their
              behavior can vary significantly. In a typical home environment,
              there are numerous different chemicals present. While our sensors
              are calibrated to identify a broad spectrum of VOCs commonly found
              indoors, they may not detect every specific type with the same
              level of precision. This calibration ensures a general but
              effective monitoring of indoor air quality, focusing on the most
              prevalent VOCs in residential settings.
            </p>
          ),
        },
        {
          title: "What colors does Mila come in?",
          details: (
            <>
              <p>Mila Air comes in classic white. </p>
              <p>
                Mila Air Mini comes in 5 decor-friendly colors, so you’ve got
                options to suit the style of every room:
              </p>
              <ul>
                <li>Purely White</li>
                <li>Smoky Gray</li>
                <li>Natural Sand</li>
                <li>Dark Moss</li>
                <li>Twilight Blue</li>
              </ul>
            </>
          ),
        },
      ],
    },
    {
      heading: "Shipping",
      content: [
        {
          title: "How long does it take for my order to ship?",
          details: (
            <>
              <p>
                Most orders (excluding preorders) typically ship out the same /
                very next business day, and deliver in 3-10 days depending on
                where you’re located. Here are our typical shipping times by
                region:
              </p>
              <ul>
                <li>US: 3-5 days</li>
                <li>Canada: 3-10 days</li>
                <li>
                  UK/Europe: 3-7 days. There’s currently a waitlist for Mila Air
                  units, which you can sign up for{" "}
                  <a
                    href="https://milamarketing.typeform.com/to/fkSJLIsg"
                    target="_blank"
                    rel="noreferrer"
                  >
                    here
                  </a>
                </li>
                <li>Australia: 3-10 days</li>
                <li>Singapore: 3-5 days</li>
              </ul>
              <p>
                Please note that Mila Air Mini is only currently available in
                the US and Canada.
              </p>
            </>
          ),
        },
        {
          title: "How will my orders ship?",
          details: (
            <>
              <p>
                We partner with different carriers depending on region. While
                this may change from time to time, these are normally who you
                can expect to deliver your Milas and filters to you:
              </p>
              <ul>
                <li>US: UPS, USPS, or DHL</li>
                <li>Canada: Purolator or Canada Post</li>
                <li>UK: Yodel or Evri</li>
                <li>Australia: Aramex or TNT</li>
                <li>Singapore: J&T or SF Express</li>
                <li>Europe (except the UK): DHL, PostNL, or FedEx</li>
              </ul>
            </>
          ),
        },
        {
          title: "Where can I find my tracking information?",
          details: (
            <p>
              We’ll send you your tracking information as soon as your order
              ships out. However, if it has been a few days and you haven’t
              heard from us, please reach out to our Care Squad at{" "}
              <a href="mailto:support@milacares.com">support@milacares.com</a>{" "}
              with your order number and we’ll be happy to look into it for you.
              In rare cases, it may be in your spam folder so don’t forget to
              give that a peek.
            </p>
          ),
        },
        {
          title: "How can I change or cancel my order?",
          details: (
            <p>
              If you need to make a change, please contact our Care Squad at{" "}
              <a href="mailto:support@milacares.com">support@milacares.com</a>{" "}
              with your order number and let us know what changes you’d like to
              make. We will do our best to accommodate any changes before your
              order ships out, but because we usually ship out within a couple
              hours of order placement, it isn’t always possible. If your order
              is already in transit, we’ll work with you to explore what options
              may be available.
            </p>
          ),
        },
        {
          title:
            "How can I manage Auto-Refill for an order I’ve already placed?",
          details: (
            <p>
              You can now activate, skip, or cancel your Auto-Refill orders
              directly through the{" "}
              <a
                href="https://account.milacares.com/"
                target="_blank"
                rel="noreferrer"
              >
                My Mila Account
              </a>{" "}
              option on our website. Simply log in to your account, navigate to
              your past orders, and select the Auto-Refill option for your
              eligible products. If you need any assistance or if Auto-Refill is
              not available in your market, please reach out to our Care Squad
              at{" "}
              <a href="mailto:support@milacares.com">support@milacares.com</a>{" "}
              with your order ID, and we’ll be glad to help you with your
              request.
            </p>
          ),
        },
        {
          title: "Will I be required to pay customs/taxes upon delivery?",
          details: (
            <p>
              No additional duties/taxes will be assessed upon delivery in any
              of the markets we support (US, Canada, UK, Australia, Singapore,
              UK, and select EU countries).
            </p>
          ),
        },
        {
          title: "Where can I find a copy of my confirmation email?",
          details: (
            <p>
              Order confirmations are emailed out automatically when you place
              an order. If you have not received a copy, please contact our Care
              Squad at{" "}
              <a href="mailto:support@milacares.com">support@milacares.com</a>{" "}
              with the email address and shipping address used to order and we
              will be happy to provide that to you. Pro tip: Don’t forget to
              check your spam folder.
            </p>
          ),
        },
      ],
    },
    {
      heading: "Set-Up",
      content: [
        {
          title: "How do I set up my Mila?",
          details: (
            <ul>
              <li>
                Operate your Mila unit in an enclosed area. Close all doors,
                windows, and other openings connecting to the outside of the
                room.
              </li>
              <li>
                Place the air purifier in a location that does not restrict the
                airflow through the top of the unit, or the intake vents on the
                sides of the unit.
              </li>
              <li>
                The unit must be placed on a leveled surface that can support
                it. Please ensure that there is a minimum of 11.8” (30 cm) For
                Mila Air and 9.8” (25 cm) for Mila Air Mini of clearance around
                the unit.
              </li>
            </ul>
          ),
        },
        {
          title: "Where should I place my Mila?",
          details: (
            <p>
              Please ensure that there is a minimum of 11.8” (30 cm) of
              clearance around the unit.{" "}
            </p>
          ),
        },
        {
          title: "What’s included in my Mila box?",
          details: (
            <>
              <p>Each Mila box comes with:</p>
              <ul>
                <li>A Mila unit</li>
                <li>A cord</li>
                <li>
                  Your filter of choice pre-loaded in your unit (please make
                  sure to remove the plastic wrap from your filter before use)
                </li>
                <li>Quick start guide</li>
              </ul>
            </>
          ),
        },
        {
          title: "How do I pair my Mila with the Mila app?",
          details: (
            <>
              <p>
                If this is your first time setting up a Mila, open the app and
                follow the in-app instructions. You’ll want to have your WiFi
                network and password handy (please note that Mila only works on
                2.4GHz frequency networks. If you have a mesh network like eero
                or Google WIFi, your router will automatically choose this for
                you.)
              </p>
              <p>
                If you already have a Mila, add a Mila from the “Rooms” screen
                using the “+” tile and follow the in-app instructions.
              </p>
              <p>
                <strong>Pro tip:</strong> You may need to disable your phone’s
                cellular data before going over the app setup to avoid
                interference with Mila’s Wi-Fi signal.
              </p>
            </>
          ),
        },
        {
          title: "Can I move my Mila to different rooms?",
          details: (
            <p>
              Mila is portable and can be moved to any room you’d like. However,
              for optimal performance, we recommend placing one in each space
              where you spend a significant amount of time in, and/or where you
              might be most concerned about your indoor air quality (e.g., your
              bedrooms).
            </p>
          ),
        },
        {
          title: "Does Mila have a night mode?",
          details: (
            <>
              <p>
                Short answer, yes! Mila comes with several sleep-related modes
                that sync up with your Bedtime Schedule.{" "}
              </p>

              <p>
                Sleep Mode dims the lights on your Mila display and makes sure
                that any changes in fan speed are indiscernible throughout the
                night.
              </p>

              <p>
                Whitenoise Mode combines your Mila’s filtering capabilities with
                the features of a sound machine, and lets you fall and stay
                asleep to the soothing sounds of fluctuating fans. Like Sleep
                Mode, the lights on your Mila display will also turn off.{" "}
              </p>

              <p>
                In addition to these two modes, you can also enable Turndown
                Service on your Mila, which will give your space a blast of
                fresh air an hour before your Bedtime Schedule kicks in. To
                learn more about Mila’s different modes, please visit{" "}
                <a
                  href="https://milacares.com/app/"
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>
                .
              </p>
            </>
          ),
        },
        {
          title:
            "I already have one Mila purifier set up in my app. How can I add another one? Do I need to create a new account?",
          details: (
            <>
              <p>
                Good news – you can add as many Milas as you like without
                creating a new account! Here's how to do it:
              </p>
              <ol>
                <li>Open your Mila app.</li>
                <li>
                  Look for the tile with a plus sign (+) – it's your gateway to
                  adding new devices.
                </li>
                <li>
                  Tap on this tile and simply follow the on-screen instructions
                  to connect your new Mila.
                </li>
              </ol>
            </>
          ),
        },
        {
          title: "How do I add a new location for my Mila devices in the app?",
          details: (
            <>
              <p>
                Whether you have Mila devices in your home, vacation house,
                college dorm, or office, setting up different locations in the
                Mila app is easy and convenient. Locations not only helps you
                manage your devices effectively but also allows you to share
                access with others, like family members or coworkers, on a
                location-by-location basis. Here's how you can add a new
                location in the app:
              </p>
              <ol>
                <li>
                  <strong>Open the Mila App:</strong> Start by opening the Mila
                  app on your mobile device.
                </li>
                <li>
                  <strong>Go to Settings:</strong> In the app, navigate to the
                  'Settings' menu. This is found in the bottom right corner of
                  the Mila app.
                </li>
                <li>
                  <strong> Access the Location Tab:</strong> Within 'Settings',
                  you'll find a tab or section labeled 'Location'. Tap on it to
                  view your current locations.
                </li>
                <li>
                  <strong>Add a New Location:</strong> In the 'Location'
                  section, you'll see a dropdown menu displaying your currently
                  selected location. Look for an option to 'Create New Location'
                  or 'Add Location'. Select this to proceed.
                </li>
                <li>
                  <strong>Name Your New Location:</strong> You will be prompted
                  to name your new location. Choose a name that easily
                  identifies the place, like 'Vacation Home' or 'Office'.
                </li>
                <li>
                  <strong>Adding Mila to the New Location:</strong> Once you've
                  created the new location, you can add your Mila device to it.
                  Ensure that you are physically near the Mila device and
                  connected to the same Wi-Fi network as the device. This is
                  necessary for the app to detect and add the Mila to your
                  selected location.
                </li>
                <li>
                  <strong>Share Access:</strong> If you wish to share access to
                  this new location with others, you can do so via the location
                  settings. Choose the option to share access and follow the
                  prompts to add users.
                </li>
              </ol>
            </>
          ),
        },
        {
          title: "Can I use the same app for both Air and Air Mini?",
          details: (
            <p>
              Yes, you can use the same app for{" "}
              <a
                href="https://apps.apple.com/us/app/mila-air/id1520776049"
                target="_blank"
                rel="noreferrer"
              >
                iOS
              </a>{" "}
              and
              <a
                href="https://play.google.com/store/apps/details?id=com.mila.androidv2"
                target="_blank"
                rel="noreferrer"
              >
                Android
              </a>{" "}
              to control both the Mila Air and Mila Air Mini. To add a new unit
              to your app, simply hit the “+” tile on your homepage and follow
              the onscreen prompts to get started. 🙂
            </p>
          ),
        },
      ],
    },
    {
      heading: "Filters",
      content: [
        {
          title: "What filters can I use with my Mila?",
          details: (
            <>
              <p>
                Mila Air comes with seven custom filters for your unique needs,
                which include: The Basic Breather, The Big Sneeze, The Rookie
                Parent, The Critter Cuddler, The Home Wrecker, The Mama-to-be,
                and The Overreactor. Learn more about each filter
                <a
                  href="https://milacares.com/filters"
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>
                .
              </p>
              <p>
                Mila Air Mini comes with four custom filer types, which include:
                The Basic Breather, The Big Sneeze, The Critter Cuddler and The
                Overreactor. You can choose your filter
                <a
                  href="https://airmini.milacares.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>
                .
              </p>
            </>
          ),
        },
        {
          title: "How often should I change my filter?",
          details: (
            <p>
              Filter life depends on usage and will last between six to nine
              months. Please note you cannot wash your filter and it should be
              disposed of in accordance with your local laws and replaced as
              needed.
            </p>
          ),
        },
        {
          title: "Can I wash my Mila Sock (pre-filter)?",
          details: (
            <p>
              Wash the Mila Sock (prefilter) by hand in cold/warm water and let
              dry completely before fitting it back on your Mila filter.
            </p>
          ),
        },
        {
          title: "How should I store my filter?",
          details: (
            <p>
              Filters should be kept out of direct sunlight and sealed in their
              original packaging until ready for use. If you need to swap out a
              filter in between uses, please store out of direct sunlight.
            </p>
          ),
        },
        {
          title: "What is a Mila Sock?",
          details: (
            <>
              <p>
                A Mila Sock acts as a washable pre-filter designed to capture
                larger particles, thus extending the life of your Mila's main
                filter. It's compatible with all seven of Mila's filter types.
                To clean the Mila Sock, we recommend vacuuming it gently to
                remove dust and debris. This can be done regularly to maintain
                its efficiency between washes. If you choose to wash it, please
                do so carefully in cold water. Please avoid hot water/heat as
                the Mila Sock may shrink.
              </p>
              <p>
                {" "}
                The{" "}
                <a
                  href="https://milacares.com/filters/critter-cuddler"
                  target="_blank"
                  rel="noreferrer"
                >
                  Critter Cuddler filter
                </a>{" "}
                comes with a Mila Sock pre-installed, ready to protect your air
                purifier from pet hair and larger particulates. Regular
                maintenance of your Mila Sock helps keep your air clean and your
                purifier running smoothly.
              </p>
            </>
          ),
        },
        {
          title: "How do I know when to replace my Mila filter?",
          details: (
            <p>
              We typically recommend changing your filters every six months,
              which we make as easy as possible for you when you sign up for
              Auto-Refill. Auto-Refill gets you a fresh new filter every six
              months, which you can pause, skip or cancel at any time. That
              said, how long your filter lasts will depend on your home’s
              environment and other factors such as overall air quality in the
              region, incoming pollen levels, ozone or wildfire smoke that could
              affect overall air quality etc. Be on the lookout for upcoming
              product releases that will give you more insight into the factors
              affecting your home’s air quality, overall health, and your filter
              life.
            </p>
          ),
        },
        {
          title: "Why might my new filter have a noticeable smell?",
          details: (
            <>
              <p>
                <strong>A: Understanding Filter Odor Phenomena</strong>
              </p>
              <ol>
                <li>
                  It's common for HEPA filters to emit a slight odor during the
                  first 24-48 hours of use. For most users, this will feel like
                  a smell of “freshness.” And while we try to prevent any of
                  that "new purifier" smell, it can be present if you have a
                  finely tuned nose. This normally dissipates in a few days.
                </li>
                <li>
                  <p>
                    <strong>
                      The smell seems to be coming from the carbon part of my
                      filter
                    </strong>
                  </p>
                  <p>
                    There are a couple of factors that can contribute to any
                    potential smells coming from your filter’s carbon:
                  </p>
                  <p>
                    <strong>Saturation:</strong> Over time, if carbon becomes
                    oversaturated, it may start emitting odors instead of
                    absorbing them. This is a sign that the filter needs to be
                    replaced.
                  </p>
                  <p>
                    <strong>Humid Storage:</strong> Storing carbon filters in
                    humid environments for extended periods can lead to odor
                    emission. However, this is unlikely in new filters shipped
                    in sealed bags.
                  </p>
                  <p>
                    <strong>Environmental Factors:</strong> in rare cases,
                    filters can also emit odors due to the interaction of their
                    materials with the environment, such as temperature.
                  </p>
                </li>
              </ol>
            </>
          ),
        },
        {
          title: "How do VOC molecule sizes affect the design of your filters?",
          details: (
            <p>
              VOCs vary in molecule size, ranging from larger molecules (like
              those causing odors) to smaller ones (such as formaldehyde). Our
              filters are engineered to target the VOC molecule size with the
              activated carbon's micropore size, ensuring the best adsorption
              rate and effectiveness in filtering various VOCs. See how Mila’s
              filters stack up against different VOCs{" "}
              <a
                href="https://milacares.com/filters"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
              .
            </p>
          ),
        },
        {
          title: "What was the primary focus in designing your current filter?",
          details: (
            <p>
              The current filter is specifically designed to target
              construction-related VOCs, with a particular emphasis on
              formaldehyde. This gas is challenging to filter due to its small
              molecule size, which is why we incorporated a honeycomb carbon
              filter specifically designed to trap formaldehyde.
            </p>
          ),
        },
        {
          title:
            "What is the role of the honeycomb carbon filter in your system?",
          details: (
            <p>
              The honeycomb carbon filter is a critical component designed to
              target and effectively adsorb formaldehyde. Its specialized
              structure is optimal for trapping these small molecules, enhancing
              the filter's ability to purify air from this specific and
              difficult-to-capture VOC.
            </p>
          ),
        },
        {
          title:
            "How does the center flow filter contribute to the effectiveness of the system?",
          details: (
            <p>
              The center flow filter contains heavy granular carbon, which gives
              the filter a long lifespan. It is designed to filter a broader
              range of large gases and odors, complementing the honeycomb carbon
              filter to provide comprehensive air purification.
            </p>
          ),
        },
        {
          title:
            "Can you explain the airflow through the filter, especially with the presence of the carbon box?",
          details: (
            <p>
              The airflow through the filter is designed to be efficient despite
              the presence of the carbon box. The filter uses a sandwich
              construction where air first passes through a prefilter, then the
              granular carbon layer, and finally through the HEPA layer, which
              is the white material visible through the mesh cover. This design
              ensures effective air filtration without blocking airflow.
            </p>
          ),
        },
        {
          title:
            "What is the structure and purpose of the center-flow carbon box in the Homewrecker and Overreactor?",
          details: (
            <>
              <p>
                The center-flow filter has a unique sandwich construction. It
                consists of a prefilter, a layer of granular carbon, and a layer
                of HEPA filter. This structure is specifically designed to
                maximize the filtration efficiency, targeting a wide range of
                particles and gases.
              </p>
              <p>
                Please note that the Home Wrecker filter option is only
                available for the Mila Air.
              </p>
            </>
          ),
        },
        {
          title:
            "How does the honeycomb filter work in conjunction with the HEPA filter?",
          details: (
            <p>
              The honeycomb carbon filter is wrapped around the inside of the
              main HEPA filter. The air first passes through the HEPA filter,
              which captures a large majority of particles, and then flows
              through the honeycomb carbon filter. This sequential filtration
              process enhances the overall effectiveness in capturing both
              particles and gases.
            </p>
          ),
        },
        {
          title:
            "Why are there two different types of carbon in some of our filters?",
          details: (
            <>
              <p>
                The{" "}
                <a
                  href="https://milacares.com/filters/home-wrecker"
                  target="_blank"
                  rel="noreferrer"
                >
                  Home Wrecker
                </a>{" "}
                and{" "}
                <a
                  href="https://milacares.com/filters/overreactor"
                  target="_blank"
                  rel="noreferrer"
                >
                  Overreactor
                </a>{" "}
                filter incorporates two types of carbon - granular and honeycomb
                - to target a broader spectrum of gases and VOCs (Volatile
                Organic Compounds). This dual approach allows for more effective
                filtration of both larger and smaller molecule gases, ensuring
                comprehensive air purification.
              </p>
              <p>
                Please note that the Home Wrecker filter is only available with
                Mila Air.
              </p>
            </>
          ),
        },

        {
          title: "What filtration rates can I expect from my Mila's?",
          details: (
            <>
              <p>
                The filtration efficiency of your Mila device depends on the
                specific filter you select. For the Mila Air, you can choose
                from 7 custom filters, while the Mila Air Mini offers 4 tailored
                options.
              </p>
              <p>
                For example, the Overreactor filter can capture 99.995% and
                99.97% of particulates for Mila Air and Air Mini, respectively,
                making it an effective choice for removing fine particles from
                the air. Each filter is designed to meet different air quality
                needs, ensuring both devices provide high-quality air
                purification. You can learn more about the filtration rates{" "}
                <a
                  href="https://milacares.com/filters"
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>
                .
              </p>
            </>
          ),
        },
        {
          title:
            "What makes your carbon filters unique in targeting different gases/VOCs?",
          details: (
            <p>
              Our carbon filters stand out due to their specialized design.
              While there are more than 500 blends of carbon available in the
              market for various applications, our filters utilize a specific
              selection of these blends, each meticulously chosen for their
              effectiveness in targeting different gases and Volatile Organic
              Compounds (VOCs). This careful selection and combination allow our
              filters to efficiently purify a wide range of VOCs, ensuring a
              cleaner and safer air quality in your environment.
            </p>
          ),
        },
      ],
    },
    {
      heading: "Troubleshooting",
      content: [
        {
          title: "The fan isn’t running.",
          details: (
            <p>
              Make sure your filter is installed correctly and the filter door
              is closed properly. Restart Mila and wait for “Calibration Mode”
              to complete (this usually takes around 70 seconds). Mila will
              automatically run in “Automagic” mode at startup. Switch to
              “Manual” mode to set your own target AQI and fan speed.
            </p>
          ),
        },
        {
          title:
            "The fan speed doesn’t change after selecting a new fan speed.",
          details: (
            <p>
              Please make sure you’ve selected MANUAL Mode before attempting to
              change the fan speed. The fan speed will decrease or increase
              gradually—this is normal. If it does not change after a prolonged
              period of time after the input, then please reach out to Mila
              Customer Care for support.
            </p>
          ),
        },
        {
          title: "My unit smells odd.",
          details: (
            <p>
              The first time you use the purifier, you might perceive a plastic
              or paint smell. This is normal, but it should disappear after a
              couple days. If the smell persists or if you sense a burning
              smell, please disconnect the purifier and reach out to Mila
              Customer Care for support.
            </p>
          ),
        },
        {
          title: "My display screen says “Connection Lost.”",
          details: (
            <p>
              This screen indicates the unit lost its network connectivity. Make
              sure the unit is within range of a stable 2.4GHz WiFi network.
              Pair the unit with the Mila mobile app. Turn OFF the unit by
              flicking the power switch on the bottom of your Mila. Wait 20
              seconds and turn ON the unit. Reboot your router.
            </p>
          ),
        },
        {
          title: "My Mila just rebooted itself.",
          details: (
            <p>
              Your Mila may reboot itself from time to time for a memory refresh
              (similar to how you might reset a router). Fear not, all your data
              is still there!
            </p>
          ),
        },
        {
          title: "I'd like to change the WiFi network my Mila is connected to.",
          details: (
            <ul>
              <li>
                Hold down the plus ( + ) button on your unit and add your Milas
                again in the app.
              </li>
              <li>
                Delete the offline Mila by opening its room page and tapping
                'Delete Room' at the bottom of the screen.
              </li>
              <li>
                If you have more than one Mila you'd like to switch the WiFi
                network for, please repeat these steps.
              </li>
            </ul>
          ),
        },
        {
          title: "What should I do if Mila is making an unusual noise?",
          details: (
            <p>
              Some sound from the fan motor is normal (pro tip: you can also
              control the amplitude of your Mila fans which doubles as a sound
              machine with modes like Whitenoise Mode), but if you notice a
              significant change or something that just sounds out of the
              ordinary, please contact our Care Squad at support@milacares.com.
              Often, a simple reset or cleaning and making sure the filter is
              regularly replaced can resolve noise issues. If it's something
              else, we'll assist you with the next steps.
            </p>
          ),
        },
        {
          title:
            "How do I put my Mila air purifier in Diagnostic Mode for troubleshooting?",
          details: (
            <>
              <p>
                You can factory reset your Mila at any time, which also allows
                you to enter Diagnostic Mode. Be aware that this process will
                erase all previous settings, including WiFi, and you'll need to
                re-pair your device to your network.
              </p>
              <p>
                <strong>Here’s how to do it:</strong>
              </p>
              <ol>
                <li>
                  <p>
                    <strong>
                      Turn off your Mila using the power switch on the bottom of
                      your Mila.
                    </strong>
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Remove the front filter panel.</strong>
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Turn on Mila.</strong> Mila will display the welcome
                    screen for five seconds, then show a Factory Reset Screen.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Follow the on-screen instructions</strong> to choose
                    Diagnostics Mode or Factory Reset Mode. If you wait a few
                    seconds, Mila will automatically enter Diagnostics Mode
                    (indicated by two columns of text).
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Replace the front door panel.</strong>
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Exit Diagnostics Mode</strong> by holding down all
                    three buttons. This returns you to the regular Mila
                    operation.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Re-pair your Mila</strong> to your network.
                  </p>
                </li>
              </ol>
            </>
          ),
        },
        {
          title:
            "What should I do if I suspect high VOC levels from recent construction in my home?",
          details: (
            <>
              <p>
                If you suspect high VOC levels from recent construction in your
                home, you’ll want to use a filter with carbon to effectively
                address VOCs such as our Home Wrecker or Overreactor filter.
                While Mila air purifiers are great for the average home, extreme
                cases with toxic chemicals might require professional
                remediation. We recommend activating Bubble Boy Mode or
                Housekeeping Service to keep your Mila units on alert. You can
                also increase the fan speed to help more air pass through the
                filter. In addition, the Mila Air can be a helpful tool for
                monitoring indoor air quality during and after construction with
                a built-in VOC sensor.
              </p>
              <p>
                This allows you to keep an eye on VOC levels in your space over
                time. If you notice elevated VOC readings, you can adjust your
                Mila to a higher fan speed, which increases the airflow through
                the filter and helps reduce the concentration of airborne
                chemicals. This would allow you to better gauge indoor air
                quality and make informed decisions about when additional
                ventilation or professional services might be needed.
              </p>
            </>
          ),
        },
        {
          title:
            "What steps can I take if I suspect high VOC levels in my home?",
          details: (
            <>
              <p>
                If you're concerned about high VOC levels, especially following
                activities like painting or bringing in new furniture, it's
                recommended to:
              </p>
              <ul>
                <li>Increase ventilation in your home.</li>
                <li>
                  Use Mila with filters optimized for VOC reduction by
                  increasing the fan speed (you’ll want something with carbon).
                </li>
                <li>
                  If you're facing persistent issues, consider consulting an
                  indoor air quality expert for a detailed assessment.
                </li>
              </ul>
            </>
          ),
        },
      ],
    },
    {
      heading: "Warranty",
      content: [
        {
          title: "Is there a warranty?",
          details: (
            <>
              <p>
                As of October 2024, all Mila units purchased directly from the
                Mila website come with a 24-month (two years) warranty. Each
                Mila purchased directly from an authorized seller in the US ({" "}
                <a
                  target="_blank"
                  href="https://www.amazon.com/"
                  rel="noreferrer"
                >
                  Amazon.com
                </a>
                , Best Buy, The Home Depot) is also covered by a 24-month
                (two-year) standard warranty.
              </p>
              <p>
                <strong>For Auto-Refill subscribers:</strong> If you are an
                Auto-Refill subscriber in good standing, you’ll automatically
                receive another year of warranty coverage, extending it to
                36-months (three years) in total.
              </p>
              <p>
                Being in good standing means you have an active Auto-Refill
                subscription and have replaced/ordered your filter with a
                genuine Mila filter in the last 12 months. Furthermore, should
                anything go awry after the three year mark, you’re eligible for
                a $99 USD replacement unit for up to two additional years
                (that’s five years total). This extended warranty coverage is
                available in the US, Canada, and Australia, with replacement
                unit costs at $99 USD (US), $149 CAD (Canada) and $209 AUD
                (Australia).
              </p>
              <p>
                Please note that disassembling your Mila unit in any way will
                void your warranty. If you encounter any issues or have
                questions about your warranty coverage, please don’t hesitate to
                reach out to us at{" "}
                <a href="mailto:support@milacares.com">support@milacares.com</a>
                .
              </p>
            </>
          ),
        },
        {
          title:
            "What additional warranty benefits do Auto-Refill subscribers receive?",
          details: (
            <>
              <p>
                If you are on Auto-Refill you will automatically get another
                year of coverage (that’s 36 months total) as long as you remain
                a subscriber in good standing. Being in good standing means you
                have an active Auto-Refill subscription, and have ordered at
                least one filter in the last 12 months. You’ll also want to make
                sure that your payment methods are up to date. Regular filter
                replacements are a key part of keeping your air – and your Mila
                – running in tip top shape.
              </p>
              <p>
                If you’re on Auto-Refill and experience any warranty-covered
                issues after the 36-month mark, and up to 60 months, you can get
                a replacement unit for:
              </p>
              <ul>
                <li>
                  <strong>United States:</strong> $99 USD
                </li>
                <li>
                  <strong>Canada:</strong> $149 CAD
                </li>
                <li>
                  <strong>Australia:</strong> $209 AUD
                </li>
              </ul>
            </>
          ),
        },
        {
          title: "What is involved with extended warranty?",
          details: (
            <p>
              For Auto-Refill subscribers who experience any warranty-covered
              issues beyond their 36 month coverage, you can get replacement
              unit at for $99 USD in the US (and $149 CAD in Canada and $209 AUD
              in Australia), which represents a significantly discounted rate
              for a new unit.
            </p>
          ),
        },
        {
          title:
            "How do I become an Auto-Refill subscriber to qualify for extended warranty coverage?",
          details: (
            <>
              <p>
                Whenever you purchase a Mila directly from us, you’ll always
                have an option to activate your Auto-Refill. In addition to
                immediate savings you’ll receive, this ensures you’ll get a
                fresh, new filter every six months, keeping your Mila running in
                tip top shape. You’ll now also enjoy an extended 12 months of
                warranty as long as you keep that Auto-Refill active, increasing
                your coverage to 36 months from the time of purchase. And if
                anything happens in years 4 and 5, we’ll replace your Mila for a
                $99 USD replacement fee ($149 CAD in Canada and $209 AUD in
                Australia).
              </p>
              <p>
                If you’re not sure what the status is of your current Mila, you
                can check on it at any time through{" "}
                <a
                  href="https://account.milacares.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  My Account
                </a>
                .
              </p>
            </>
          ),
        },
        {
          title:
            "What happens if I’m an Auto-Refill subscriber and I cancel my subscription?",
          details: (
            <p>
              If you cancel your Auto-Refill, you’ll no longer be eligible for
              extended coverage for the third year and the discounted
              replacement offer for the fourth and fifth years. The standard
              two-year warranty will still apply starting at the time of
              purchase.
            </p>
          ),
        },
        {
          title: "What do I need to make a warranty claim?",
          details: (
            <p>
              In general, we ask that you have your receipt or order information
              ready. We also understand that it may not always be available
              (e.g., if your Mila was a gift). If you can't find your receipt or
              order confirmation, please contact us at{" "}
              <a href="mailto:support@milacares.com">support@milacares.com</a>.
              We will do our best to assist you and explore all possible
              solutions, but please be prepared to provide some basic details
              about the original purchase(r) such as name and/or email to help
              verify the order. Please note that this policy only applies to
              Mila units purchased directly from the Mila website or authorized
              sellers (Amazon where either Mila USA Inc. or Amazon is the
              seller, The Home Depot, Best Buy).
            </p>
          ),
        },
        {
          title: "How do I claim a warranty for my Mila unit?",
          details: (
            <>
              <p>
                To claim a warranty for your Mila unit, visit this link to fill
                out the warranty claim form:{" "}
                <a
                  href="https://milamarketing.typeform.com/to/u1aEP1qs#email=xxxxx&ticketid=xxxxx&concern=xxxxx&status=xxxxx"
                  target="_blank"
                  rel="noreferrer"
                >
                  Mila Warranty Claim Form
                </a>
                . This form will take about 10 minutes to complete. You’ll be
                asked for:
              </p>
              <ul>
                <li>
                  Basic contact information such as your name, address and email
                  address
                </li>
                <li>Your MAC address</li>
                <li>Details around what you’re experiencing</li>
                <li>Your order details</li>
                <li>
                  If needed, you’ll also have an opportunity to submit a video
                  that illustrates what you’re experiencing.
                </li>
              </ul>
            </>
          ),
        },
        {
          title:
            "My Mila stopped working but I’m outside of the warranty period. What are my options?",
          details: (
            <p>
              If your Mila stops working after the warranty period (24 months
              for all customers, and 36 months for Auto-Refill subscribers, with
              extended coverage to 60 months for Auto-Refill subscribers in the
              US, Canada, and Australia), you can still contact our Care Squad
              at{" "}
              <a href="mailto:support@milacares.com">support@milacares.com</a>{" "}
              for assistance.
            </p>
          ),
        },
        {
          title: "Can I transfer my warranty if I gift my Mila? ",
          details: (
            <p>
              Yes, if you gift your Mila, you can also transfer the warranty,
              provided that the original proof of purchase is available and the
              warranty period has not expired. Any Auto-Refill benefits will not
              transfer.
            </p>
          ),
        },
      ],
    },
    {
      id: "alexa",
      heading: "Smart Home - Amazon Alexa",
      content: [
        {
          title: "How do I connect Amazon Alexa to my Milas?",
          details: (
            <ul>
              <li>
                Start by enabling the{" "}
                <a
                  href="https://alexa.amazon.com/spa/index.html#skills/dp/B098XF1CL8/?ref=skill_dsk_skb_sr_0&qid=0e078db1-daa3-48d9-be6a-19fd11fbfd95"
                  target="_blank"
                  rel="noreferrer"
                >
                  Mila Skill
                </a>{" "}
                — you'll want to have your Amazon log-in handy.
              </li>
              <li>
                Follow the on-screen instructions to link your Mila account to
                Amazon Alexa.
              </li>
            </ul>
          ),
        },
        {
          title: "What can I ask my Alexa to do?",
          details: (
            <>
              <p>
                Using your Amazon Alexa, you can ask Mila to set certain modes
                or to get the 411 on what's happening with your home air
                quality. Commands you can use include:
              </p>
              <p>
                <strong>Set the Mode</strong>
              </p>
              <ul>
                <li>Alexa, ask Mila to set mode to Automagic</li>
                <li>Alexa, ask Mila to turn up/down the fan (by N%)</li>
                <li>Alexa, ask Mila to set/turn off Child Lock</li>
                <li>Alexa, ask Mila to turn on/off Whitenoise Mode</li>
                <li>Alexa, ask Mila to start a Deep Clean</li>
              </ul>
              <p>
                <strong>Set the Fan Speed</strong>
              </p>
              <ul>
                <li>Alexa, ask Mila to set fan speed to N%</li>
                <li>Alexa, ask Mila to pause</li>
                <li>Alexa, ask Mila to resume</li>
              </ul>
              <p>
                <strong>Get the 411</strong>
              </p>
              <ul>
                <li>Alexa, ask Mila what my indoor air quality is</li>
                <li>Alexa, ask Mila what my outdoor air quality is</li>
                <li>Alexa, ask Mila what my air quality is</li>
              </ul>
              <p>
                <strong>Need an extra hand? </strong>
              </p>
              <ul>
                <li>Alexa, ask Mila for an introduction</li>
                <li>Alexa, ask Mila for some help</li>
                <li>Alexa, ask Mila what is TVOC</li>
                <li>Alexa, ask Mila what is AQI</li>
              </ul>
              <p>
                If you have multiple Milas, set asks for a specific Mila by
                adding "in the [Room Name]" to any of these asks (e.g., Ask Mila
                to set Child Lock in the Nursery). You can find your name in the
                "Rooms" screen of your Mila app.{" "}
              </p>
            </>
          ),
        },
        {
          title: 'How do I pronounce "Mila"?',
          details: <p>Mila is pronounced "mee-la".</p>,
        },
        {
          title: "I need some more help.",
          details: (
            <>
              <p>
                <strong>Connecting with Amazon Alexa</strong>
              </p>
              <p>
                You can visit Amazon’s website for help with Alexa, including
                its connection to your Milas. For example, you may need to
                toggle permissions for the Mila skill, or you may need to
                disable the skill and then re-enable it. Amazon’s website offers
                instructions and additional troubleshooting suggestions{" "}
                <a
                  href="https://www.amazon.com/gp/help/customer/display.html/?nodeId=202013760"
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>
                .
              </p>
              <p>
                <strong>I have a question about Mila</strong>
              </p>
              <p>Please send our Care Squad a note at support@milacares.com.</p>
            </>
          ),
        },
      ],
    },
    {
      id: "googlehome",
      heading: "Smart Home - Google Home",
      content: [
        {
          title: "How do I connect Google Home to my Milas?",
          details: (
            <ul>
              <li>
                Open the Google Home app and ensure you’re logged into the
                Google account connected to your Google Home devices.
              </li>
              <li>
                Navigate to: Home > Settings > Google Assistant > All > Home
                Control.
              </li>
              <li>
                In the Home Control section, search for “Mila Smart Home” and
                follow the prompts to link your Mila to Google Home.
              </li>
            </ul>
          ),
        },
        {
          title: "What can I ask my Google Home to do?",
          details: (
            <>
              <p>
                Using your Google Home, you can ask Mila to set certain modes or
                to get the 411 on what's happening with your home air quality.
                Commands you can use include:
              </p>
              <p>
                <strong>Set the Mode</strong>
              </p>
              <ul>
                <li>Ok Google, ask Mila to set mode to Automagic</li>
                <li>Ok Google, ask Mila to turn up/down the fan (by N%)</li>
                <li>Ok Google, ask Mila to set/turn off Child Lock</li>
                <li>Ok Google, ask Mila to turn on/off Whitenoise Mode</li>
                <li>Ok Google, ask Mila to start a Deep Clean</li>
              </ul>
              <p>
                <strong>Set the Fan Speed</strong>
              </p>
              <ul>
                <li>Ok Google, ask Mila to set fan speed to N%</li>
                <li>Ok Google, ask Mila what my outdoor air quality is</li>
                <li>Ok Google, ask Mila what my air quality is</li>
              </ul>
              <p>
                <strong>Get the 411</strong>
              </p>
              <ul>
                <li>Ok Google, ask Mila what my indoor air quality is</li>
                <li>Ok Google, ask Mila what my outdoor air quality is</li>
                <li>Ok Google, ask Mila what my air quality is</li>
              </ul>
              <p>
                <strong>Need an extra hand?</strong>
              </p>
              <ul>
                <li>Ok Google, ask Mila for an introduction</li>
                <li>Ok Google, ask Mila for some help</li>
                <li>Ok Google, ask Mila what is TVOC</li>
                <li>Ok Google, ask Mila what is AQI</li>
              </ul>
              <p>
                If you have multiple Milas, set asks for a specific Mila by
                adding "in the [Room Name]" to any of these asks (e.g., Ask Mila
                to set Child Lock in the Nursery). You can find your name in the
                "Rooms" screen of your Mila app.{" "}
              </p>
            </>
          ),
        },
        {
          title: 'How do I pronounce "Mila"?',
          details: <p>Mila is pronounced "mee-la".</p>,
        },
        {
          title: "I need some more help.",
          details: (
            <>
              <p>
                <strong>Connecting with Google Home</strong>
              </p>
              <p>
                You can visit Google's website for help with Google Home,
                including its connection to your Milas. For example, you may
                need to unlink and re-link your Milas. Google's website offers
                instructions and additional troubleshooting suggestions{" "}
                <a
                  href="https://support.google.com/googlenest/answer/9159862"
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>
                .
              </p>
              <p>
                <strong>I have a question about Mila</strong>
              </p>
              <p>
                Please send our Care Squad a note at{" "}
                <a
                  href="mailto:support@milacares.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  support@milacares.com.
                </a>
              </p>
            </>
          ),
        },
      ],
    },
    {
      heading: "Smart Home - HomeKit",
      content: [
        {
          title: "What does integrating Mila with HomeKit mean?",
          details: (
            <p>
              HomeKit integration allows you to connect and control your Mila
              devices with Apple's HomeKit platform. This means you can manage
              your devices using the Home app on your Apple devices or through
              Siri voice commands. You can also get started with this{" "}
              <a
                href="https://docs.milacares.com/Mila_and_HomeKit_Getting_Started.pdf"
                target="_blank"
                rel="noreferrer"
              >
                HomeKit Quick Start Guide.
              </a>
            </p>
          ),
        },
        {
          title: "How do I set up HomeKit integration with my Mila device?",
          details: (
            <ul>
              <li>Open the Mila app on your iPhone.</li>
              <li>Go to the room where your Mila is placed and select it.</li>
              <li>
                Tap “Add to HomeKit” at the bottom. The app will begin verifying
                Mila’s compatibility with HomeKit.
              </li>
              <li>
                If your Mila needs some additional updates first before booting
                up in HomeKit mode, you should see a screen on your display
                prompting you <br /> The Mila app will check if Mila’s firmware
                is current. If so, it will automatically update. <br /> If
                already up-to-date, continue to the next step.
              </li>
              <li>Mila may undergo another reboot.</li>
              <li>
                Upon reboot, you’ll be able to follow the on-screen HomeKit
                instructions: name your Mila and choose its room.
              </li>
              <li>Voila! Your Mila is now integrated with HomeKit. 🤗</li>
            </ul>
          ),
        },
        {
          title: "What can I do with HomeKit and Mila?",
          details: (
            <p>
              Once connected, you can control your Mila devices through the
              Apple Home app or using Siri. This includes turning devices on or
              off, adjusting settings, or even setting up automated routines,
              and scenes that trigger based on time, location, or other device
              states.
            </p>
          ),
        },
        {
          title:
            "What information/sensors can I access through HomeKit on Mila?",
          details: (
            <>
              <p>
                Through HomeKit integration with Mila, users can access a
                variety of sensors and information. Specifically:
              </p>
              <ul>
                <li>
                  <strong>Air Quality Sensor:</strong> This sensor provides
                  real-time data on the air quality in your space, giving
                  insights into particulate matter, carbon dioxide, and carbon
                  monoxide.
                </li>
                <li>
                  <strong>Temperature Sensor:</strong> Monitors the current room
                  temperature, allowing for more informed decisions about
                  heating or cooling needs.
                </li>
                <li>
                  <strong>Humidity Sensor:</strong> Measures the relative
                  humidity in the room, allowing for more informed decisions
                  about humidity in the space.
                </li>
                <li>
                  <strong>Proximity Sensor:</strong> Detects the presence or
                  absence of individuals in the room. This can be used to
                  trigger specific actions or settings in the Home app.
                </li>
                <li>
                  <strong>Fan Speed:</strong> Monitor and adjust the fan speed
                  of your Mila device directly from HomeKit.
                </li>
                <li>
                  <strong>Device Status:</strong> Quickly view if your Mila is
                  powered on/off, or in specific modes like Manual or Auto Mode.
                </li>
              </ul>
            </>
          ),
        },
        {
          title: "Is HomeKit integration secure?",
          details: (
            <p>
              Yes. HomeKit uses strong encryption to keep your device
              communications secure. In fact, Apple's HomeKit platform is known
              for its stringent security measures.
            </p>
          ),
        },
        {
          title:
            "I'm having trouble setting up HomeKit integration. Where can I get help?",
          details: (
            <p>
              If you're having trouble, please contact our Care Squad at{" "}
              <a href="mailto:support@milacares.com">support@milacares.com</a>.
              We're here to help!{" "}
            </p>
          ),
        },
        {
          title: "Do I need an Apple device to use HomeKit with Mila?",
          details: (
            <p>
              Yes, you will need an Apple device running iOS (like your phone)
              to set up and manage HomeKit. Once set up, you can use Siri on any
              of your Apple devices to control your Mila devices.
            </p>
          ),
        },
        {
          title:
            "Can I use HomeKit and Alexa/Google Assistant at the same time?",
          details: (handlers) => (
            <p>
              Yes, Mila devices can be simultaneously linked to multiple smart
              home platforms. You can use{" "}
              <a
                href="https://milacares.com/support#alexa"
                onClick={handlers.scrollToAlexa}
                target="_blank"
                rel="noreferrer"
              >
                Alexa
              </a>
              ,{" "}
              <a
                href="https://milacares.com/support#googlehome"
                onClick={handlers.scrollToGoogleHome}
                target="_blank"
                rel="noreferrer"
              >
                Google Assistant
              </a>
              , and HomeKit at the same time, but the exact processes and
              capabilities might differ between them. Please note Mila is not
              Matter compatible right now.
            </p>
          ),
        },
        {
          title: "Will there be additional costs for using HomeKit with Mila?",
          details: (
            <p>
              No, HomeKit integration comes as a part of the Mila software
              package and doesn't require any additional fees.
            </p>
          ),
        },
      ],
    },
    {
      heading: "Home Health Report",
      content: [
        {
          title: "What is the Home Health Report? ",
          details: (
            <>
              <p>
                The Home Health Report is a monthly summary of your home’s
                health, including indoor air quality. It provides insights into
                various air quality metrics and trends from the past month, such
                as PM2.5 levels, VOC exposure, and more.
              </p>
              <p>
                To receive your Home Health Report, please make sure you’re
                running app version 1.16. Your Mila unit needs to also be online
                and collecting data for the past 30 days (so if you’re new to
                Mila or your Mila has been offline for a while, you may not see
                your report just yet!). This release is the start of a series of
                updates to come to your Home Health Report. And as the very
                first release, you might run into some quirks or edge cases. As
                always, we welcome your feedback, and please feel free to reach
                out to our Care Squad at{" "}
                <a href="mailto:support@milacares.com">support@milacares.com</a>{" "}
                at any time.
              </p>
            </>
          ),
        },
        {
          title: "How often will I receive the Home Health Report? ",
          details: (
            <p>
              You will receive the Home Health Report once a month in your Mila
              app. It summarizes the air quality data collected over the
              previous month, pointing out key trends and insights.{" "}
            </p>
          ),
        },
        {
          title: "What can I do with HomeKit and Mila?",
          details: (
            <p>
              Once connected, you can control your Mila devices through the
              Apple Home app or using Siri. This includes turning devices on or
              off, adjusting settings, or even setting up automated routines,
              and scenes that trigger based on time, location, or other device
              states.
            </p>
          ),
        },
        {
          title: "How can I access my Home Health Report?",
          details: (
            <div className="faq-grid">
              <div className="faq-grid__content">
                <p>
                  You can access your Home Health Report through the Mila app on
                  the Milabot screen. In addition to seeing a notification pop
                  up at the top of the screen, you’ll also see a notification at
                  the bottom left when a new report is available.{" "}
                </p>
              </div>
              <ImageLoader
                className="faq-grid__media faq-screenshot-img img-relative"
                placeholderAspectRatio={1125 / 2216}
                image={hhrScreenshot}
                webp={hhrScreenshotWebp}
                alt="Home Health Report screenshot"
              />
            </div>
          ),
        },
        {
          title: "What kind of data is included in the Home Health Report? ",
          details: (
            <p>
              The report includes data on various air quality metrics such as
              PM2.5 levels, VOCs (Volatile Organic Compounds), CO2 levels,
              temperature, and humidity. It also provides information on the
              overall air quality trends in and outside of your home.
            </p>
          ),
        },
        {
          title: "How is the data in the Home Health Report collected? ",
          details: (
            <p>
              The data is collected using your Mila air purifier’s sensors,
              which continuously monitor the air quality in your home. We then
              combine this data with external information like outdoor AQI,
              pollen counts, and more to give you a comprehensive view of your
              home’s health. This data is then aggregated and analyzed to create
              your monthly Home Health Report.{" "}
            </p>
          ),
        },
        {
          title: "What should I do if I don't receive my Home Health Report? ",
          details: (
            <p>
              As our first iteration of the Home Health Report, this feature is
              in public beta, and we are rolling out on a per-Milieber basis. If
              you’re running the latest app (version 1.16), you are eligible to
              receive a report. Reports are generated monthly and typically
              appear in the first week of the new month. Please note that you
              must have at least 30 days of data for a report to be generated.
              If you’ve had your Mila online and running for 30 days or more and
              still don’t have your Home Health Report, first check the Milabot
              screen on the Mila app. New reports will be prominently displayed
              at the top of your feed until you view them, at which point they
              will be available at the bottom of the Milabot screen (your
              Milabot is organized chronologically starting with your most
              recent air quality events at the very top and going back in time
              as you scroll down). If you don’t see a report, there might not be
              enough data for your unit just quite yet. If you still feel like
              your report is missing, please contact our Care Squad at{" "}
              <a href="mailto:support@milacares.com">support@milacares.com</a>{" "}
              with your Mila account email.
            </p>
          ),
        },
        {
          title: "Why is it important to monitor indoor air quality? ",
          details: (
            <p>
              Monitoring indoor air quality is crucial because poor air quality
              can affect your health and well-being, as well as the overall
              health and longevity of your home. By understanding and improving
              the air quality in your home, you can reduce the risk of
              respiratory issues, allergies, and other health problems.
            </p>
          ),
        },
        {
          title:
            "How can I improve my home’s air quality based on the report? ",
          details: (
            <p>
              The Home Health Report provides actionable insights and
              recommendations to help you improve your home’s health, including
              your indoor air quality. This may include suggestions for
              ventilation, using Mila more, reducing sources of pollution, and
              maintaining optimal humidity levels while also protecting your
              home from damage caused by pollutants, moisture, and other
              environmental factors.
            </p>
          ),
        },

        {
          title: "What should I do if I notice unusual data in my report? ",
          details: (
            <p>
              If you notice any unusual data in your Home Health Report, you can
              reach out to our Care Squad by emailing us at{" "}
              <a href="mailto:support@milacares.com">support@milacares.com</a>{" "}
              for assistance and further guidance.
            </p>
          ),
        },
        {
          title:
            "What should I do if certain data/metrics are missing from my Home Health Report? ",
          details: (
            <p>
              If you notice certain metrics missing from your Home Health
              Report, it could be due to a lack of sufficient data. For example,
              if there is no pollen data available for your area, it could mean
              there isn’t a reliable station nearby or that the station it’s
              pointed to is currently offline. Please note that pollen data is
              currently available only in the US and Europe. If you believe
              there is an error or if data was previously available, please
              contact our Care Squad at{" "}
              <a href="mailto:support@milacares.com">support@milacares.com</a>{" "}
              for assistance.
            </p>
          ),
        },
        {
          title: "Can I customize the Home Health Report? ",
          details: (
            <p>
              Currently, the Home Health Report is designed to provide insights
              that are typically helpful for most homes. For example, it
              includes metrics such as PM2.5 levels, VOCs, CO2 levels,
              temperature, and humidity, along with trends and actionable
              recommendations. However, we are always looking to improve and
              welcome your feedback on additional features or customizations you
              would like to see (e.g., layout, additional metrics etc.).
            </p>
          ),
        },
        {
          title: "Can I share the Home Health Report with friends and family?",
          details: (
            <p>
              We're reviewing ways to share individual metrics/data cards within
              the Home Health Report! However, for now, the report is only
              available for personal viewing. But don't worry, we're working
              hard to make it easy to share your progress and insights with
              friends and family. Stay tuned for updates! In the meantime, you
              can invite friends and family to join you in a specific location
              within the Mila app. While individual metrics/data cards can’t be
              shared directly, invited guests will have access to the Home
              Health Report and its insights.
            </p>
          ),
        },
      ],
    },
    {
      heading: "Air Mini",
      content: [
        {
          title: "What is the Mila Air Mini?",
          details: (
            <p>
              The Mila Air Mini is a compact, smart air purifier (Mila’s
              sister!) designed to improve indoor air quality by using advanced
              sensors and smart operation modes. It’s perfect for smaller spaces
              and can be controlled via the Mila smartphone app.
            </p>
          ),
        },
        {
          title: "What is the coverage area of the Mila Air Mini?",
          details: (
            <p>
              The Mila Air Mini is ideal for rooms up to 800 square feet, making
              it perfect for bedrooms, offices, and smaller living areas.
            </p>
          ),
        },
        {
          title: "How quiet is the Mila Air Mini?",
          details: (
            <p>
              The Mila Air Mini operates at a minimum noise level of 21.7 dB and
              a maximum of 58 dB, ensuring that it works quietly while still
              providing powerful air purification. Unlike traditional air
              purifiers that run at a constant setting, the Mila Air Mini
              intelligently adjusts its noise levels based on your
              presence—quieter when you’re in the room and ramping up for deeper
              cleaning when you’re away. With Quiet Mode activated, you can
              expect up to 30% less noise for a peaceful environment.
            </p>
          ),
        },
        {
          title: "Does the Mila Air Mini remove odors?",
          details: (
            <p>
              Yes, the Mila Air Mini is effective at removing odors thanks to
              its filters, especially those that come packed with carbon (the
              Critter Cuddler and the Overreactor filters). They each contain
              activated carbon which is excellent for capturing VOCs and odors.
            </p>
          ),
        },
        {
          title: "Can I control the Mila Air Mini with my smartphone?",
          details: (
            <p>
              Yes, the Mila Air Mini can be controlled using the Mila app,
              compatible with iOS 15 and above, and Android 10 and above. The
              app allows you to set smart modes, visualize sensor data, and
              control the device remotely. If you already have a Mila, simply go
              to the “Rooms” screen in the app and follow the instructions to
              add your Mila Air Mini.
            </p>
          ),
        },
        {
          title: "How do I set up the Mila Air Mini?",
          details: (
            <p>
              Setting up the Mila Air Mini is simple. Plug in the device,
              download the Mila app for{" "}
              <a
                href="https://apps.apple.com/us/app/mila-air/id1520776049"
                target="_blank"
                rel="noreferrer"
              >
                Apple iOS
              </a>{" "}
              or{" "}
              <a
                href="https://play.google.com/store/apps/details?id=com.mila.androidv2&pcampaignid=web_share&pli=1"
                target="_blank"
                rel="noreferrer"
              >
                Android
              </a>
              , and follow the on-screen instructions to pair the air purifier
              with your smartphone and WiFi network. If you already have other
              Mila units in your home paired to the app, simply hit the “+” tile
              on your homepage and follow the onscreen prompts to add your Mini.
            </p>
          ),
        },
        {
          title: "How does Mila Air Mini monitor air quality?",
          details: (
            <>
              <p>
                The Mila Air Mini is equipped with 5 advanced sensors,
                including:
              </p>
              <ul>
                <li>Airborne particulate sensor for PM1.0, PM2.5, and PM10</li>
                <li>Temperature</li>
                <li>Humidity sensor</li>
                <li>Carbon monoxide (CO) sensor</li>
                <li>Room presence</li>
              </ul>
              <p>
                These sensors enable the Mila Air Mini to provide real-time air
                quality data and adjust its operation accordingly.
              </p>
            </>
          ),
        },
        {
          title:
            "How does the Mila Air Mini handle different air quality issues?",
          details: (
            <p>
              The Mila Air Mini can address a range of air quality issues, from
              common allergens and pollutants to more specific concerns like pet
              dander and cooking odors. The choice of filter allows you to
              tailor the device to your specific needs.
            </p>
          ),
        },
        {
          title: "Can I use the Mila Air Mini in multiple rooms?",
          details: (
            <p>
              The Mila Air Mini is portable and can be easily moved from room to
              room. However, for continuous air purification in multiple rooms,
              we recommend having a dedicated Mila Air Mini for each space.
            </p>
          ),
        },
        {
          title: "What is the power consumption of the Mila Air Mini?",
          details: (
            <p>
              The Mila Air Mini uses a brushless DC motor with a maximum power
              consumption of 42W. At minimum fan speed, it consumes around 3W,
              and in Energy Save Mode, it uses around 2W.
            </p>
          ),
        },
        {
          title: "What operating modes does the Mila Air Mini have?",
          details: (
            <>
              <p>The Mila Air Mini offers several operating modes:</p>
              <ul>
                <li>
                  Automagic Mode: Automatically adjusts fan speed based on AQI
                  measurements.
                </li>
                <li>Manual Mode: Allows you to manually control fan speed.</li>
              </ul>
              <p>
                These modes can be controlled via the on-screen display and on
                the smartphone app.
              </p>
            </>
          ),
        },
        {
          title: "What should I do if my Mila Air Mini isn’t working properly?",
          details: (
            <p>
              If you encounter any issues with your Mila Air Mini, please
              contact Mila Care Squad for support at{" "}
              <a href="mailto:support@milacares.com">support@milacares.com</a>.
            </p>
          ),
        },
        {
          title: "Can the Mila Air Mini help with seasonal allergies?",
          details: (
            <p>
              Yes, the Mila Air Mini is highly effective at reducing allergens
              such as pollen and dust, and comes with two filters that are
              specifically designed to filter out allergens – the Big Sneeze and
              the Critter Cuddler filters.
            </p>
          ),
        },
        {
          title: "What maintenance is required for the Mila Air Mini?",
          details: (
            <p>
              Regular maintenance includes cleaning the exterior with a soft
              cloth and replacing the filters regularly to ensure your unit
              operates at peak performance.
            </p>
          ),
        },
        {
          title: "Is the Mila Air Mini safe to use around pets and children?",
          details: (
            <p>
              Yep! The Mila Air Mini is designed to be safe for use around pets
              and children. It features secure housing and filters to ensure
              clean air without exposing anyone to harmful components. Both Mila
              Mini and Mila Air use mechanical filtration, no ionizers or ozone
              involved. It also includes thoughtful features such as Child Lock
              Mode to keep curious fingers (and paws) away.
            </p>
          ),
        },
        {
          title: "How often should I run my Mila Air Mini?",
          details: (
            <p>
              For optimal air quality, it’s recommended to run your Mila Air
              Mini continuously. The Automagic Mode feature will adjust the fan
              speed based on real-time air quality readings, ensuring efficient
              operation and energy use.
            </p>
          ),
        },
        {
          title: "What colors does the Mila Air Mini come in?",
          details: (
            <p>
              The Mila Air Mini comes in five decor-friendly colors: Purely
              White, Smoky Gray, Natural Sand, Dark Moss, and Twilight Blue.
            </p>
          ),
        },
        {
          title: "How often do I need to replace the filters?",
          details: (
            <p>
              You should replace the filters in your Mila Air Mini every 6
              months. To ensure you never miss a replacement, we recommend
              joining the Auto-Refill program. With Auto-Refill, you’ll receive
              a fresh filter delivered to your door every 6 months, and your
              first filter is on us. This helps maintain optimal air quality and
              ensures your Mila Air Mini operates at peak efficiency.
            </p>
          ),
        },
        {
          title: "What filters can be used with the Mila Air Mini?",
          details: (
            <>
              <p>
                Mila Air Mini comes with four custom Mini-sized filters that
                include:
              </p>
              <ul>
                <li>The Basic Breather: Our essential everyday filter.</li>
                <li>The Big Sneeze: Designed for allergy sufferers.</li>
                <li>
                  The Critter Cuddler: Perfect for pet owners, clearing the air
                  of pet dander.
                </li>
                <li>
                  The Overreactor: The mother of all filters. Perfect for
                  tackling just about anything in the air.
                </li>
              </ul>
            </>
          ),
        },
        {
          title: "Does Mila Air Mini come with a mini filter?",
          details: (
            <p>
              Yes, the Mila Air Mini comes with a filter. When you purchase the
              Mila Air Mini, you can choose from a selection of 4 filters.
              Additionally, if you subscribe to Auto-Refill, your first filter
              is included for free, and you’ll receive a fresh filter every 6
              months with free shipping and enjoy extended warranty coverage.
              You can cancel your Auto-Refill at any time.
            </p>
          ),
        },
        {
          title: "What’s included in the box with Air Mini?",
          details: (
            <p>
              The Mila Air Mini package includes the air purifier unit, a
              pre-installed filter of your choice, a power adapter, and a quick
              start guide.
            </p>
          ),
        },
        {
          title:
            "What makes the Mila Air Mini different from other air purifiers?",
          details: (
            <>
              <p>
                The Mila Air Mini is a powerful, compact purifier designed for
                medium-sized rooms up to 600 sq. ft., with a high CADR of 357
                m³/h and 100% HEPA filtration. It offers four customizable
                filters to tackle pet dander, allergens, odors, and VOCs. What
                sets Mia apart is its intelligence, and Air Mini comes with six
                air quality sensors and an adaptive Automagic Mode that includes
                settings such as Whitenoise Mode, Housekeeping Service and
                Bubble Boy Mode. Smart home integrations (Alexa, Google
                Assistant, Apple HomeKit) ensure seamless air management. With
                in-depth insights and controls via the Webby-award-winning Mila
                app, MilaAir Mini delivers a personalized and intuitive
                air-cleaning experience.
              </p>
            </>
          ),
        },
      ],
    },
    {
      heading: "Mila Air 3 ",
      content: [
        {
          title: "Will Mila Air 3 still support the same filters as Mila Air?",
          details: (
            <p>
              Yes! Mila Air 3 is compatible with the full range of Mila filters
              you know and trust.
            </p>
          ),
        },

        {
          title:
            "How does the new motor in Mila Air 3 compare to previous versions?",
          details: (
            <>
              <p>
                Mila Air 3 introduces several key upgrades over the original
                Mila Air, improving performance, durability, and quietness:
              </p>
              <ul>
                <li>
                  <strong>Improved Performance:</strong> Mila Air 3 features a
                  higher CADR (Clean Air Delivery Rate), with a range of{" "}
                  <strong>262 m³/hr to 460 m³/hr</strong>, compared to the
                  original Mila Air’s range of{" "}
                  <strong>254 m³/hr to 447 m³/hr</strong>.{" "}
                </li>
                <li>
                  <strong>Quieter Operation:</strong> The new motor and fan
                  design make Mila Air 3 quieter than ever. Mila Air 3 operates
                  at a maximum noise level of <strong>58 dB</strong>, slightly
                  quieter than the original Mila Air’s <strong>59 dB</strong>,
                  and its minimum noise level is an ultra-quiet{" "}
                  <strong>23 dB</strong>, ideal for bedrooms, offices, or other
                  quiet spaces.
                </li>
                <li>
                  <strong>Advanced Motor and Fan Design:</strong> Mila Air 3
                  features a Nidec motor, known for its precision and
                  durability, paired with a custom-engineered fan. This
                  combination enhances stability, minimizes wear, and improves
                  heat dissipation for a quieter and longer-lasting performance.
                </li>
              </ul>
              <p>
                In short, Mila Air 3 builds upon the original Mila Air’s strong
                foundation, delivering superior air-cleaning performance,
                quieter operation, and enhanced durability while keeping the
                smart features Mila users know and love.
              </p>
            </>
          ),
        },
        {
          title: "How quiet is Mila Air 3?",
          details: (
            <p>
              Mila Air 3 is whisper-quiet, and operates at 16% fewer decibels
              than the original Mila Air at max speeds, and goes as low as 23 dB
              (literally pin-drop-in-a-library quiet). It's ideal for bedrooms,
              offices, and any space where you want peace and clean air.
            </p>
          ),
        },

        {
          title: "How large of a room can Mila Air 3 handle?",
          details: (
            <>
              <p>
                Mila Air 3 clears the air in rooms of different sizes
                effectively, offering tailored air purification based on your
                needs. As general guidance, here’s a look at what you can expect
                in different spaces:
              </p>
              <ul>
                <li>
                  <strong>Up to 1,034 sq ft:</strong> In larger spaces, Mila
                  delivers <strong>2 Air Changes per Hour (ACH)</strong>,
                  providing basic coverage for everyday air quality. It takes
                  approximately <strong>30 minutes to clean the air</strong> in
                  a room this size.
                </li>
                <li>
                  <strong>At 400 sq ft:</strong> Perfect for living rooms, Mila
                  delivers <strong>5 ACH</strong>, meeting CDC recommendations
                  for allergy and viral reduction. It takes just{" "}
                  <strong>12 minutes to clean</strong> the air in these spaces.
                </li>
                <li>
                  <strong>At 200 sq ft:</strong> Ideal for bedrooms, Mila
                  achieves <strong>10 ACH</strong>, offering clean-room-level
                  filtration. The air is cleaned in a quick{" "}
                  <strong>6 minutes</strong>.
                </li>
              </ul>
              <p>
                No matter the room—whether it’s your bedroom, living room, or
                home office—Mila ensures the air you breathe is clean and safe.
              </p>
            </>
          ),
        },
        {
          title: "What makes Mila Air 3 different from Mila Air?",
          details: (
            <p>
              Mila Air 3 boasts the same great looks as Mila Air, but comes with
              a completely redesigned interior with a new fan and motor. This
              means even higher performance with 16% less noise. Plus, it's
              built to last – designed to run for 4.7 billion revolutions, the
              equivalent of your car running for 6.2 million miles.
            </p>
          ),
        },
        {
          title: "Has performance improved with the new motor and fan?",
          details: (
            <p>
              Yes, Mila Air 3 delivers improved performance with a higher CADR
              (Clean Air Delivery Rate), increasing from a range of{" "}
              <strong>
                254 m<sup>3</sup>/hr to 447 m<sup>3</sup>/hr
              </strong>{" "}
              in Mila Air 2 to a range of{" "}
              <strong>
                262 m<sup>3</sup>/hr to 460 m<sup>3</sup>/hr
              </strong>{" "}
              in Mila Air 3.
            </p>
          ),
        },
      ],
    },
  ],
};

export default supportFaqStatic;
