import React from 'react';

import BSImage from 'assets/images/performanceRefresh/filterRecommendation/filter-recommandation-BS.jpg';
import BSImageWebp from 'assets/images/performanceRefresh/filterRecommendation/filter-recommandation-BS.jpg?as=webp';

const FilterRecommendationStatic = {
  BS: {
    color: '#FFCA0C',
    label: 'I want to conquer allergies',
    image: {
      src: BSImage,
      srcWebp: BSImageWebp,
    },
    description: (
      <p>
        Grab our
        {' '}
        <strong>Big Sneeze filter</strong>
        , throw Mila into
        {' '}
        <strong>Bubble Boy Mode</strong>
        , and pay attention to her
        {' '}
        <strong>pollen alerts</strong>
        . You’ll feel like a brand new breather.
      </p>
    ),
    cleanAir: (
      <>
        416 Clean Air
        {' '}
        <br />
        Delivery Rate (m³/hr)
      </>
    ),
    hepa: (
      <>
        99.97% Efficiency
        {' '}
        <br />
        H13 HEPA Filter
      </>
    ),
    link: 'https://milacares.com/buy',
  },
  BB: {
    color: '#FF0C71FF',
    label: 'I want to fight dander',
    image: {
      src: BSImage,
      srcWebp: BSImageWebp,
    },
    description: (
      <p>
        Grab our
        {' '}
        <strong>Big Sneeze filter</strong>
        , throw Mila into
        {' '}
        <strong>Bubble Boy Mode</strong>
        , and pay attention to her
        {' '}
        <strong>pollen alerts</strong>
        . You’ll feel like a brand new breather.
      </p>
    ),
    cleanAir: (
      <>
        416 Clean Air
        <br />
        Delivery Rate (m³/hr)
      </>
    ),
    hepa: <>99.97% Efficiency H13 HEPA Filter</>,
    link: 'https://milacares.com/buy',
  },
  CC: {
    color: '#1CC198FF',
    label: 'I want to sleep better',
    image: {
      src: BSImage,
      srcWebp: BSImageWebp,
    },
    description: (
      <p>
        Grab our
        {' '}
        <strong>Big Sneeze filter</strong>
        , throw Mila into
        {' '}
        <strong>Bubble Boy Mode</strong>
        , and pay attention to her
        {' '}
        <strong>pollen alerts</strong>
        . You’ll feel like a brand new breather.
      </p>
    ),
    cleanAir: <>416 Clean Air Delivery Rate (m³/hr)</>,
    hepa: <>99.97% Efficiency H13 HEPA Filter</>,
    link: 'https://milacares.com/buy',
  },
  MB: {
    color: '#0CFFC6FF',
    label: ' I want  to feel healthier',
    image: {
      src: BSImage,
      srcWebp: BSImageWebp,
    },
    description: (
      <p>
        Grab our
        {' '}
        <strong>Big Sneeze filter</strong>
        , throw Mila into
        {' '}
        <strong>Bubble Boy Mode</strong>
        , and pay attention to her
        {' '}
        <strong>pollen alerts</strong>
        . You’ll feel like a brand new breather.
      </p>
    ),
    cleanAir: (
      <>
        416 Clean Air
        <br />
        Delivery Rate (m³/hr)
      </>
    ),
    hepa: (
      <>
        99.97% Efficiency
        <br />
        H13 HEPA Filter
      </>
    ),
    link: 'https://milacares.com/buy',
  },
  RP: {
    color: '#0C8EFFFF',
    label: 'I want to protect my littles',
    image: {
      src: BSImage,
      srcWebp: BSImageWebp,
    },
    description: (
      <p>
        Grab our
        {' '}
        <strong>Big Sneeze filter</strong>
        , throw Mila into
        {' '}
        <strong>Bubble Boy Mode</strong>
        , and pay attention to her
        {' '}
        <strong>pollen alerts</strong>
        . You’ll feel like a brand new breather.
      </p>
    ),
    cleanAir: (
      <>
        416 Clean Air
        <br />
        Delivery Rate (m³/hr)
      </>
    ),
    hepa: (
      <>
        99.97% Efficiency
        <br />
        H13 HEPA Filter
      </>
    ),
    link: 'https://milacares.com/buy',
  },
};

export default FilterRecommendationStatic;
