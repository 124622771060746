import React from 'react';

import leafFlutteringInWind from 'assets/images/leaf-fluttering-in-wind.png';
import sneezingFace from 'assets/images/sneezing-face.png';
import baby from 'assets/images/baby.png';
import dogFace from 'assets/images/dog-face.png';
import construction from 'assets/images/construction.png';
import woman from 'assets/images/woman.png';
import explodingHead from 'assets/images/exploding-head.png';

const filterImoji = {
  BB: leafFlutteringInWind,
  BS: sneezingFace,
  CC: dogFace,
  RP: baby,
  HW: construction,
  MB: woman,
  OR: explodingHead,
};

export default filterImoji;
