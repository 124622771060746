import React from 'react';

import filterImageOne from 'assets/images/performanceRefresh/filterTab/performance-filter-img-01.png';
import filterImageOneWebp from 'assets/images/performanceRefresh/filterTab/performance-filter-img-01.png?as=webp';

import filterImageTwo from 'assets/images/performanceRefresh/filterTab/performance-filter-img-02.png';
import filterImageTwoWebp from 'assets/images/performanceRefresh/filterTab/performance-filter-img-02.png?as=webp';

import filterImageThree from 'assets/images/performanceRefresh/filterTab/performance-filter-img-03.png';
import filterImageThreeWebp from 'assets/images/performanceRefresh/filterTab/performance-filter-img-03.png?as=webp';

const filterTabStatic = [
  {
    tabButton: true,
    air: {
      title: 'The best filtration technology around (and lots of it).',
      highlightText: (
        <>
          Her filters are jam-packed with up to 26 ft2 of HEPA, the gold
          standard in ultrafine particle filtration.
        </>
      ),
      description: (
        <p>
          It’s the ONLY filtration technology repeatedly proven to filter out
          99.97% of airborne particles 0.3 micrometers (µm) in diameter, such as
          smoke, allergens, house dust, PM2.5, and PM10.
        </p>
      ),
      image: {
        src: filterImageOne,
        webp: filterImageOneWebp,
      },
    },
    airMini: {
      title: 'Sample Mini  - The best filtration technology around (and lots of it).',
      highlightText: (
        <>
          Her filters are jam-packed with up to 26 ft2 of HEPA, the gold
          standard in ultrafine particle filtration. mini
        </>
      ),
      description: (
        <p>
          Sample Mini  - It’s the ONLY filtration technology repeatedly proven to filter out
          99.97% of airborne particles 0.3 micrometers (µm) in diameter, such as
          smoke, allergens, house dust, PM2.5, and PM10.
        </p>
      ),
      image: {
        src: filterImageTwo,
        webp: filterImageTwoWebp,
      },
    },
  },
  {
    tabButton: false,
    air: {
      title: 'Five-sided filters for maximum air intake.',
      highlightText: (
        <>
          While traditional filters just have a front and a back, Mila’s
          patented Dual-Flow filters have 5 sides to suck in dirty air.
        </>
      ),
      description: (
        <p>
          You don’t need to be a math whiz to know that’s a lot more surface
          area—so you get more air cleaned, faster. 💨
        </p>
      ),
      image: {
        src: filterImageTwo,
        webp: filterImageTwoWebp,
      },
    },
    airMini: {
      title: 'Five-sided filters for maximum air intake. Mini',
      highlightText: (
        <>
          While traditional filters just have a front and a back, Mila’s
          patented Dual-Flow filters have 5 sides to suck in dirty air. mini
        </>
      ),
      description: (
        <p>
          You don’t need to be a math whiz to know that’s a lot more surface
          area—so you get more air cleaned, faster. 💨
        </p>
      ),
      image: {
        src: filterImageTwo,
        webp: filterImageTwoWebp,
      },
    },
  },
  {
    tabButton: true,
    air: {
      title: 'Up to 2 lbs. of heavy-duty granular activated carbon.',
      highlightText: (
        <>
          This stuff is the best at filtering odors and harmful VOCs—and unlike
          other manufacturers, we don’t skimp on it.
        </>
      ),
      description: (
        <p>
          Mila’s unique design allows her to hold a big ol’ 3-inch bed of
          carbon, giving you best-in-class filtration for household stinks and
          harmful VOCs from paints, carpets, and pressed woods.
        </p>
      ),
      image: {
        src: filterImageThree,
        webp: filterImageThreeWebp,
      },
    },
    airMini: {
      title: 'Sample Mini  - Up to 2 lbs. of heavy-duty granular activated carbon.',
      highlightText: (
        <>
          Sample Mini - This stuff is the best at filtering odors and harmful VOCs—and unlike
          other manufacturers, we don’t skimp on it.
        </>
      ),
      description: (
        <p>
          Sample Mini  - Mila’s unique design allows her to hold a big ol’ 3-inch bed of
          carbon, giving you best-in-class filtration for household stinks and
          harmful VOCs from paints, carpets, and pressed woods.
        </p>
      ),
      image: {
        src: filterImageThree,
        webp: filterImageThreeWebp,
      },
    },
  },
];

export default filterTabStatic;
