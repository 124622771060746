import React from 'react';
import iconDoller from 'assets/images/performanceRefresh/filterServices/icon-doller.svg';
import iconLove from 'assets/images/performanceRefresh/filterServices/icon-love.svg';
import iconshield from 'assets/images/performanceRefresh/filterServices/icon-shield.svg';
import iconSupport from 'assets/images/performanceRefresh/filterServices/icon-support.svg';
import filterImoji from '../filterImoji';

const filterServicesStatic = {
  title: (
    <>
      Winner of the “Best Customer Service Experience” award by
      {' '}
      <em>Modern Retail.</em>
    </>
  ),
  description:
    'We’re not shy to tell you we’re committed to you for the long haul. 😊',
  services: [
    {
      color: '#FFFFFF',
      svg: iconDoller,
      title: '30-day Mila-Backed Guarantee',
      description: (
        <p>
          If you don’t love Mila, you can return her within 30 days for a full
          refund, minus shipping and handling.
          {' '}
        </p>
      ),
    },
    {
      color: '#88F6EE',
      svg: iconshield,
      title: 'Air-Tight 2-Year Warranty',
      description: (
        <p>
          Should something happen with your Mila, no worries—you’re covered.
          (Even if your kid took a baseball bat to it.)
        </p>
      ),
    },
    {
      color: '#FFFFFF',
      svg: iconSupport,
      title: 'Support from the Mila Care Squad',
      description: (
        <p>
          When you need help, our responsive team (not some automated phone
          system) has your back. Reach out to us at support@milacares.com any
          time.
        </p>
      ),
    },
    {
      color: '#BC9DF9',
      svg: iconLove,
      title: '5-Year Warranty for Filter Subscribers',
      description: (
        <p>
          When you need help, our responsive team (not some automated phone
          system) has your back. Reach out to us at support@milacares.com any
          time.
        </p>
      ),
    },
  ],

  testimonial: {
    name: 'Vanessa',
    label: 'Critter Cuddler addict',
    image: filterImoji.CC,
    quote: (
      <>
        “I’m always pleasantly surprised at the customer service. I appreciate
        the beyond expectation experience. I also know now that someone will
        respond to emails with an answer and solution. Thank you.”
      </>
    ),
  },
};

export default filterServicesStatic;
