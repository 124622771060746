import React from "react";
import { Helmet } from "react-helmet-async";
import loadable from "@loadable/component";

const ComponentWrapper = loadable(() =>
  import("../components/performance-refresh/ComponentWrapper")
);

/**
 * @param {Object} props
 * @return {JSX.Element}
 * @constructor
 */

const PerformanceRefresh = (props) => {
  const { route } = props;

  return (
    <>
      <Helmet>
        <title>{route?.meta?.title}</title>
      </Helmet>
      <ComponentWrapper />
    </>
  );
};

export default { component: PerformanceRefresh };
