const filterHepaStatic = {
  category: [
    {
      class: 'HEPA <br /> <span>Class</span>',
    },
    {
      efficiency: 'HEPA <br /> <span>Efficiency</span>',
    },
    {
      coating: 'HEPA <br /> <span>Coating</span>',
    },
    {
      blend: 'Carbon <span>Blend</span>',
    },
    {
      weight: 'Carbon <span>Weight</span>',
    },
  ],

  air: {
    BB: {
      tooltip: 'paint, detergents',
      class: 'H12',
      efficiency: '99.6%',
      coating: 'N/A',
      blend: 'N/A',
      weight: 'N/A',
    },
    BS: {
      tooltip: 'paint, detergents',
      class: 'H13',
      efficiency: '99.97%',
      coating: 'Anti-allergen',
      blend: 'N/A',
      weight: 'N/A',
    },
    CC: {
      tooltip: 'paint, detergents',
      class: 'H13',
      efficiency: '99.97%',
      coating: 'Anti-allergen',
      blend: 'Modified Granular Carbon',
      weight: '1.1 lb (500g)',
    },
    RP: {
      tooltip: 'paint, detergents',
      class: 'H12',
      efficiency: '99.6%',
      coating: 'Anti-bacterial',
      blend: 'Pure Granular Carbon',
      weight: '1.1 lb (500g)',
    },
    HW: {
      tooltip: 'paint, detergents',
      class: 'H12',
      efficiency: '99.6%',
      coating: 'N/A',
      blend: 'Modified Granular Carbon',
      weight: '1.58 lb (719g)',
    },
    MB: {
      tooltip: 'paint, detergents',
      class: 'H14',
      efficiency: 'H14',
      coating: 'Anti-bacterial',
      blend: 'Pure Granular Carbon',
      weight: '0.58 lb (267g)',
    },
    OR: {
      tooltip: 'paint, detergents',
      class: 'H14',
      efficiency: 'H14',
      coating: 'Anti-bacterial',
      blend: 'Modified Granular Carbon',
      weight: '1.58 lb',
    },
  },
  airMini: {
    BB: {
      tooltip: 'paint, detergents',
      class: 'H14',
      efficiency: '90.6%',
      coating: 'N/A',
      blend: 'N/A',
      weight: 'N/A',
    },
    BS: {
      tooltip: 'paint, detergents',
      class: 'H14',
      efficiency: '90.6%',
      coating: 'Anti-allergen',
      blend: 'N/A',
      weight: 'N/A',
    },
    CC: {
      tooltip: 'paint, detergents',
      class: 'H14',
      efficiency: '90.6%',
      coating: 'Anti-allergen',
      blend: 'Modified Granular Carbon',
      weight: '1.1 lb (500g)',
    },
    RP: {
      tooltip: 'paint, detergents',
      class: 'H14',
      efficiency: '90.6%',
      coating: 'Anti-bacterial',
      blend: 'Pure Granular Carbon',
      weight: '1.1 lb (500g)',
    },
    HW: {
      tooltip: 'paint, detergents',
      class: 'H12',
      efficiency: '99.6%',
      coating: 'N/A',
      blend: 'Modified Granular Carbon',
      weight: '1.58 lb (719g)',
    },
    MB: {
      tooltip: 'paint, detergents',
      class: 'H14',
      efficiency: 'H14',
      coating: 'Anti-bacterial',
      blend: 'Pure Granular Carbon',
      weight: '0.58 lb (267g)',
    },
    OR: {
      tooltip: 'paint, detergents',
      class: 'H14',
      efficiency: 'H14',
      coating: 'Anti-bacterial',
      blend: 'Modified Granular Carbon',
      weight: '1.58 lb',
    },
  },
};

export default filterHepaStatic;
