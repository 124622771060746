import React from 'react';
import leafFlutteringInWind from 'assets/images/leaf-fluttering-in-wind.png';
import sneezingFace from 'assets/images/sneezing-face.png';
import baby from 'assets/images/baby.png';
import dogFace from 'assets/images/dog-face.png';
import construction from 'assets/images/construction.png';
import woman from 'assets/images/woman.png';
import explodingHead from 'assets/images/exploding-head.png';

import basicBreather from 'assets/images/performanceRefresh/filterSpecs/filter-basic-breather-box.png';
import bigSneeze from 'assets/images/performanceRefresh/filterSpecs/filter-big-sneeze-box.png';
import critterCuddler from 'assets/images/performanceRefresh/filterSpecs/filter-critter-cuddler-box.png';
import mamaToBe from 'assets/images/performanceRefresh/filterSpecs/filter-mama-to-be-box.png';
import overReactor from 'assets/images/performanceRefresh/filterSpecs/filter-overreactor-box.png';
import rookieParents from 'assets/images/performanceRefresh/filterSpecs/filter-rookie-parents-box.png';
import homewrecker from 'assets/images/performanceRefresh/filterSpecs/filter-home-wrecker-box.png';

import { FILTER_TYPES } from '../../../shared/enums';

const filterSpecsData = {
  header: {
    title: <>Mila comes with personalized filters for every air breather.</>,
    description: (
      <p>
        Whatever particular air challenges you face, from allergies and pet
        odors to urban smog and home reno dust—Mila has you covered with 7
        custom filters.
      </p>
    ),
  },
  categories: [
    {
      id: FILTER_TYPES.BB,
      name: 'Basic Breather',
      image: leafFlutteringInWind,
    },
    {
      id: FILTER_TYPES.BS,
      name: 'Big Sneeze',
      image: sneezingFace,
    },
    {
      id: FILTER_TYPES.CC,
      name: 'Critter Cuddler',
      image: dogFace,
    },

    {
      id: FILTER_TYPES.RP,
      name: 'Rookie Parents',
      image: baby,
    },
    {
      id: FILTER_TYPES.HW,
      name: 'Homewrecker',
      image: construction,
    },
    {
      id: FILTER_TYPES.MB,
      name: 'Mama to Be',
      image: woman,
    },
    {
      id: FILTER_TYPES.OR,
      name: 'Over Reactor',
      image: explodingHead,
    },
  ],
  filters: {
    [FILTER_TYPES.BB]: {
      gridType: 'one',
      primaryColor: '#F0F1F3',
      secondaryColor: '#D6DADE',
      image: {
        src: basicBreather,
      },
      title: 'The Basic Breather.',
      subtitle: 'For the everyday lover of clean air',
      description: (
        <p>
          This brilliant essential is designed for those who don’t have any
          specific needs, other than the desire for clean, fresh air.
        </p>
      ),
      link: {
        text: 'Shop now',
        url: 'https://milacares.com/buy',
      },

      air: {
        gridOne: [
          {
            className: 'p-spec-item-w-33',
            label: 'Performance',
            description: (
              <p>
                The Basic Breather does its most efficient work with a CADR of
                {' '}
                <strong>
                  460 m
                  <sup>3</sup>
                  /hr
                </strong>
                .
              </p>
            ),
          },
          {
            className: 'p-spec-item-w-33',
            label: 'Superpowers',
            description: (
              <p>
                Filter out
                {' '}
                <strong>99.9%</strong>
                {' '}
                of smoke, dust and PM2.5 in
                under
                {' '}
                <strong>30 minutes</strong>
                {' '}
                with this high-efficiency
                filter.
              </p>
            ),
          },
          {
            className: 'p-spec-item-w-33',
            label: 'Coverage',
            description: (
              <p>
                Max room coverage of
                {' '}
                <strong>1,015 sq ft</strong>
                {' '}
                and a optimal room coverage of
                {' '}
                <strong>406 sq ft</strong>
                .
              </p>
            ),
          },
        ],
        gridTwo: [
          {
            className: 'p-spec-item-w-100',
            label: 'HEPA Filter',
            description: (
              <p>
                <strong>H12 HEPA</strong>
                {' '}
                with 99.6% efficiency.
              </p>
            ),
          },
          {
            className: 'p-spec-item-w-100',
            label: 'Awards',
            description: (
              <p>
                <strong>“Best Smart Air Purifier”</strong>
                {' '}
                — HouseFresh.
              </p>
            ),
          },
        ],
      },
      airMini: {
        gridOne: [
          {
            className: 'p-spec-item-w-33',
            label: 'Performance',
            description: (
              <p>
                With a CADR of
                {' '}
                <strong>357 m³/hr</strong>
                , the Basic Breather is
                our most efficient filter.
              </p>
            ),
          },
          {
            className: 'p-spec-item-w-33',
            label: 'Superpowers',
            description: (
              <p>
                Filter out
                {' '}
                <strong> 99.9%</strong>
                {' '}
                of smoke, dust and PM2.5 in
                under
                {' '}
                <strong>30 minutes</strong>
                {' '}
                with this high-efficiency
                filter.
              </p>
            ),
          },
          {
            className: 'p-spec-item-w-33',
            label: 'Coverage',
            description: (
              <p>
                Max room coverage of
                {' '}
                <strong>788 sq ft</strong>
                {' '}
                and a optimal
                room coverage of
                {' '}
                <strong>315 sq ft</strong>
                .
              </p>
            ),
          },
        ],
        gridTwo: [
          {
            className: 'p-spec-item-w-100',
            label: 'HEPA Filter',
            description: (
              <p>
                <strong>H12 HEPA</strong>
                {' '}
                with 99.6% efficiency.
              </p>
            ),
          },
          {
            className: 'p-spec-item-w-100',
            label: 'Awards',
            description: (
              <p>
                <strong>“Best New Product of 2025”</strong>
                {' '}
                — Newsweek Magazine.
              </p>
            ),
          },
        ],
      },
    },

    [FILTER_TYPES.BS]: {
      gridType: 'two',
      primaryColor: '#FFF5DE',
      secondaryColor: '#FFE4A8',
      image: {
        src: bigSneeze,
      },
      title: 'The Big Sneeze.',
      subtitle: 'Helping you fight back against allergens',
      description: (
        <p>
          This brilliant essential is designed for those who don’t have any
          specific needs, other than the desire for clean, fresh air.
        </p>
      ),
      link: {
        text: 'Shop now',
        url: 'https://milacares.com/buy',
      },

      air: {
        gridOne: [
          {
            className: 'p-spec-item-w-25',
            label: 'Performance',
            description: (
              <p>
                The Big Sneeze boasts a CADR of
                {' '}
                <strong>412 m³/hr</strong>
                ,
                giving it the power to flex between large and small rooms.
              </p>
            ),
          },
          {
            className: 'p-spec-item-w-25',
            label: 'Superpowers',
            description: (
              <p>
                It’s a champ against pollen, with a
                {' '}
                <strong>99.9%</strong>
                {' '}
                tested removal rate in 30 minutes.
              </p>
            ),
          },
          {
            className: 'p-spec-item-w-25',
            label: 'Coverage',
            description: (
              <p>
                Max room coverage of
                {' '}
                <strong>909 sq ft</strong>
                {' '}
                and a optimal
                room coverage of
                {' '}
                <strong>364 sq ft.</strong>
              </p>
            ),
          },
          {
            className: 'p-spec-item-w-25',
            label: 'Carbon Filter',
            description: (
              <p>
                Add an extra layer of defense with an anti-allergen coating.
              </p>
            ),
          },
          {
            className: 'p-spec-item-w-50',
            label: 'HEPA Filter',
            description: (
              <p>
                <strong>HEPA Filter</strong>
                {' '}
                with 99.6% efficiency.
              </p>
            ),
          },
          {
            className: 'p-spec-item-w-50',
            label: 'Awards',
            description: (
              <p>
                <strong>“Best Overall Air Purifier for Allergies”</strong>
                {' '}
                —
                Popular Science.
              </p>
            ),
          },
        ],
      },

      airMini: {
        gridOne: [
          {
            className: 'p-spec-item-w-25',
            label: 'Performance',
            description: (
              <p>
                The Big Sneeze boasts a CADR of
                {' '}
                <strong>330 m³/hr.</strong>
              </p>
            ),
          },
          {
            className: 'p-spec-item-w-25',
            label: 'Superpowers',
            description: (
              <p>
                It’s a champ against pollen, with a
                {' '}
                <strong>99.9%</strong>
                {' '}
                tested removal rate in 30 minutes.
              </p>
            ),
          },
          {
            className: 'p-spec-item-w-25',
            label: 'Coverage',
            description: (
              <p>
                Max room coverage of
                {' '}
                <strong>728 sq ft</strong>
                {' '}
                and a optimal
                room coverage of
                {' '}
                <strong>291 sq ft.</strong>
              </p>
            ),
          },
          {
            className: 'p-spec-item-w-25',
            label: 'Carbon Filter',
            description: (
              <p>
                Add an extra layer of defense with an anti-allergen coating.
              </p>
            ),
          },
          {
            className: 'p-spec-item-w-50',
            label: 'HEPA Filter',
            description: (
              <p>
                <strong>HEPA Filter</strong>
                {' '}
                with 99.6% efficiency.
              </p>
            ),
          },
          {
            className: 'p-spec-item-w-50',
            label: 'Awards',
            description: (
              <p>
                <strong>“Best New Product of 2025”</strong>
                {' '}
                — Newsweek Magazine.
              </p>
            ),
          },
        ],
      },
    },

    [FILTER_TYPES.CC]: {
      gridType: 'three',
      primaryColor: '#FDE0E5',
      secondaryColor: '#FBABBA',
      image: {
        src: critterCuddler,
      },
      title: 'The Critter Cuddler.',
      subtitle: 'Clearing the air for fur families.',

      description: (
        <p>
          This brilliant essential is designed for those who don’t have any
          specific needs, other than the desire for clean, fresh air.
        </p>
      ),
      link: {
        text: 'Shop now',
        url: 'https://milacares.com/buy',
      },
      air: {
        gridOne: [
          {
            className: 'p-spec-item-w-100',
            label: 'Performance',
            description: (
              <p>
                The Critter Cuddler delivers a CADR of
                {' '}
                <strong>350 m³/hr.</strong>
              </p>
            ),
          },
          {
            className: 'p-spec-item-w-50',
            label: 'Coverage',
            description: (
              <p>
                Max room coverage of
                {' '}
                <strong>773 sq ft</strong>
                {' '}
                and an optimal
                room coverage of
                {' '}
                <strong>309 sq ft.</strong>
              </p>
            ),
          },
          {
            className: 'p-spec-item-w-50',
            label: 'Coating',
            description: (
              <p>
                Add an extra layer of defense with an anti-allergen coating.
              </p>
            ),
          },
        ],
        gridTwo: [
          {
            className: 'p-spec-item-w-100',
            label: 'Superpowers',
            description: (
              <p>
                Odors and allergies are no match, with a
                {' '}
                <strong>75.60%</strong>
                {' '}
                ammonia removal rate, and the power to filter out
                {' '}
                <strong>99.9%</strong>
                {' '}
                of pet dander in just
                {' '}
                <strong>30 minutes.¹</strong>
              </p>
            ),
          },
          {
            className: 'p-spec-item-w-100',
            label: 'HEPA Filter',
            description: (
              <p>
                H13 HEPA with
                {' '}
                <strong>99.97% </strong>
                {' '}
                efficiency.
              </p>
            ),
          },
        ],
        gridThree: [
          {
            className: 'p-spec-item-w-50',
            label: 'Carbon Filter',
            description: (
              <p>
                <strong>0.84lbs (381g)</strong>
                {' '}
                of modified granular carbon.
              </p>
            ),
          },
          {
            className: 'p-spec-item-w-50',
            label: 'Mila Sock',
            description: (
              <p>
                Filter out larger particles and debris with a prefilter for
                added protection.
              </p>
            ),
          },
          {
            className: 'p-spec-item-w-100',
            label: 'Awards',
            description: (
              <p>
                <strong>“Best Air Purifier for Pets”</strong>
                {' '}
                - Cosmopolitan.
              </p>
            ),
          },
        ],
      },
      airMini: {
        gridOne: [
          {
            className: 'p-spec-item-w-100',
            label: 'Performance',
            description: (
              <p>
                The Critter Cuddler delivers a CADR of
                {' '}
                <strong>290 m³/hr.</strong>
              </p>
            ),
          },
          {
            className: 'p-spec-item-w-55',
            label: 'Coverage',
            description: (
              <p>
                Max room coverage of
                {' '}
                <strong>640 sq ft</strong>
                {' '}
                and an optimal
                room coverage of
                <strong>256 sq ft.</strong>
              </p>
            ),
          },
          {
            className: 'p-spec-item-w-45',
            label: 'Coating',
            description: (
              <p>
                Add an extra layer of defense with an anti-allergen coating.
              </p>
            ),
          },
        ],
        gridTwo: [
          {
            className: 'p-spec-item-w-100',
            label: 'Superpowers',
            description: (
              <p>
                Odors and allergies are no match, with a
                {' '}
                <strong>79%</strong>
                {' '}
                ammonia removal rate, and the power to filter out
                <strong>99.9%</strong>
                {' '}
                of pet dander in just
                <strong> 30 minutes.¹</strong>
              </p>
            ),
          },
          {
            className: 'p-spec-item-w-100',
            label: 'HEPA Filter',
            description: (
              <p>
                H13 HEPA with
                {' '}
                <strong>99.97% </strong>
                {' '}
                efficiency.
              </p>
            ),
          },
        ],
        gridThree: [
          {
            className: 'p-spec-item-w-50',
            label: 'Carbon Filter',
            description: (
              <p>
                <strong>0.21lb (95g)</strong>
                {' '}
                of modified granular carbon.
              </p>
            ),
          },
          {
            className: 'p-spec-item-w-50',
            label: 'Mila Sock',
            description: (
              <p>
                Filter out larger particles and debris with a prefilter for
                added protection.
              </p>
            ),
          },
          {
            className: 'p-spec-item-w-100',
            label: 'Awards',
            description: (
              <p>
                <strong>“Best New Product of 2025”</strong>
                {' '}
                — Newsweek Magazine.
              </p>
            ),
          },
        ],
      },
    },

    [FILTER_TYPES.RP]: {
      gridType: 'two',
      primaryColor: '#EFE3FF',
      secondaryColor: '#E0C8FF',
      image: {
        src: rookieParents,
      },
      title: 'The Rookie Parents.',
      subtitle: 'Making the air clean and safe for your littles.',
      description: (
        <p>
          This brilliant essential is designed for those who don’t have any
          specific needs, other than the desire for clean, fresh air.
        </p>
      ),
      link: {
        text: 'Shop now',
        url: 'https://milacares.com/buy',
      },

      air: null,

      airMini: {
        gridOne: [
          {
            className: 'p-spec-item-w-25',
            label: 'Performance',
            description: (
              <p>
                The Big Sneeze boasts a CADR of
                {' '}
                <strong>330 m³/hr.</strong>
              </p>
            ),
          },
          {
            className: 'p-spec-item-w-25',
            label: 'Superpowers',
            description: (
              <p>
                It’s a champ against pollen, with a
                {' '}
                <strong>99.9%</strong>
                {' '}
                tested removal rate in 30 minutes.
              </p>
            ),
          },
          {
            className: 'p-spec-item-w-25',
            label: 'Coverage',
            description: (
              <p>
                Max room coverage of
                {' '}
                <strong>728 sq ft</strong>
                {' '}
                and a optimal
                room coverage of
                {' '}
                <strong>291 sq ft.</strong>
              </p>
            ),
          },
          {
            className: 'p-spec-item-w-25',
            label: 'Carbon Filter',
            description: (
              <p>
                <strong>0.21lb (95g)</strong>
                {' '}
                of granular carbon.
              </p>
            ),
          },

          {
            className: 'p-spec-item-w-50',
            label: 'HEPA Filter',
            description: (
              <p>
                <strong>HEPA Filter</strong>
                {' '}
                with 99.6% efficiency.
              </p>
            ),
          },
          {
            className: 'p-spec-item-w-50',
            label: 'Awards',
            description: (
              <p>
                <strong>“Best Smart Air Purifier”</strong>
                {' '}
                – Gear Patrol.
              </p>
            ),
          },
        ],
      },
    },
    [FILTER_TYPES.HW]: {
      gridType: 'two',
      primaryColor: '#FFECDB',
      secondaryColor: '#FFCD9E',
      image: {
        src: homewrecker,
      },
      title: 'The Home Wrecker.',
      subtitle: 'Protecting you from that “new home” smell',
      description: (
        <p>
          This brilliant essential is designed for those who don’t have any
          specific needs, other than the desire for clean, fresh air.
        </p>
      ),
      link: {
        text: 'Shop now',
        url: 'https://milacares.com/buy',
      },

      air: null,

      airMini: {
        gridOne: [
          {
            className: 'p-spec-item-w-25',
            label: 'Performance',
            description: (
              <p>
                The Home Wrecker goes to work with a CADR of
                {' '}
                <strong>308 m³/hr.</strong>
              </p>
            ),
          },
          {
            className: 'p-spec-item-w-25',
            label: 'Superpowers',
            description: (
              <p>
                The Home Wrecker eats formaldehyde for breakfast, with a FO
                removal rate of
                {' '}
                <strong>95.6%.</strong>
              </p>
            ),
          },
          {
            className: 'p-spec-item-w-25',
            label: 'Coverage',
            description: (
              <p>
                Max room coverage of
                {' '}
                <strong>680 sq ft</strong>
                {' '}
                and an optimal
                room coverage of
                {' '}
                <strong>272 sq ft.</strong>
              </p>
            ),
          },
          {
            className: 'p-spec-item-w-25',
            label: 'Carbon Filter',
            description: (
              <p>
                <strong>1.96lbs (889g)</strong>
                {' '}
                of modified granular carbon.
              </p>
            ),
          },
          {
            className: 'p-spec-item-w-50',
            label: 'HEPA Filter',
            description: (
              <p>
                <strong>HEPA Filter</strong>
                {' '}
                with 99.6% efficiency.
              </p>
            ),
          },
          {
            className: 'p-spec-item-w-50',
            label: 'Awards',
            description: (
              <p>
                <strong> “Best Air Purifiers of 2024”</strong>
                {' '}
                — Yahoo! Life.
              </p>
            ),
          },
        ],
      },
    },

    [FILTER_TYPES.MB]: {
      gridType: 'two',
      primaryColor: '#E8FCFB',
      secondaryColor: '#A4F4EE',
      image: {
        src: mamaToBe,
      },
      title: 'The Mama-to-Be.',
      subtitle: 'Extra protection when you’re breathing for two',
      description: (
        <p>
          This brilliant essential is designed for those who don’t have any
          specific needs, other than the desire for clean, fresh air.
        </p>
      ),
      link: {
        text: 'Shop now',
        url: 'https://milacares.com/buy',
      },

      air: null,

      airMini: {
        gridOne: [
          {
            className: 'p-spec-item-w-25',
            label: 'Performance',
            description: (
              <p>
                The Mama-to-Be keeps things zen with a CADR of
                {' '}
                <strong>262 m³/hr.</strong>
              </p>
            ),
          },
          {
            className: 'p-spec-item-w-25',
            label: 'Superpowers',
            description: (
              <p>
                Questionable smells? Not in this house. The Mama-to-Be is a
                powerhouse against odors with its
                {' '}
                <strong>0.70 lbs</strong>
                {' '}
                of
                carbon.
              </p>
            ),
          },
          {
            className: 'p-spec-item-w-25',
            label: 'Coverage',
            description: (
              <p>
                Max room coverage of
                {' '}
                <strong>578 sq ft</strong>
                {' '}
                and an optimal
                room coverage of
                {' '}
                <strong>231 sq ft.</strong>
              </p>
            ),
          },
          {
            className: 'p-spec-item-w-25',
            label: 'Carbon Filter',
            description: (
              <p>
                <strong> 0.70lbs (318g)</strong>
                {' '}
                of granular carbon.
              </p>
            ),
          },
          {
            className: 'p-spec-item-w-50',
            label: 'HEPA Filter',
            description: (
              <p>
                H14 HEPA with
                {' '}
                <strong>99.995%</strong>
                {' '}
                efficiency.
              </p>
            ),
          },
          {
            className: 'p-spec-item-w-50',
            label: 'Awards',
            description: (
              <p>
                <strong>“Best Air Purifiers of 2025”</strong>
                {' '}
                — Tom’s Guide.
              </p>
            ),
          },
        ],
      },
    },

    [FILTER_TYPES.OR]: {
      gridType: 'two',
      primaryColor: '#DAE6FC',
      secondaryColor: '#B5CCF9',
      image: {
        src: overReactor,
      },
      title: 'The Overreactor.',
      subtitle: 'Helping you fight back against allergens',
      description: (
        <p>
          This brilliant essential is designed for those who don’t have any
          specific needs, other than the desire for clean, fresh air.
        </p>
      ),
      link: {
        text: 'Shop now',
        url: 'https://milacares.com/buy',
      },

      air: {
        gridOne: [
          {
            className: 'p-spec-item-w-25',
            label: 'Performance',
            description: (
              <p>
                The Overreactor is always ready for action with with a CADR of
                {' '}
                <strong>265 m³/hr.</strong>
              </p>
            ),
          },
          {
            className: 'p-spec-item-w-25',
            label: 'Superpowers',
            description: (
              <p>
                Conquers VOCs with a
                {' '}
                <strong>97.89%</strong>
                {' '}
                removal rate and
                proven to wipe out
                {' '}
                <strong>98.9%</strong>
                {' '}
                of viruses in under
                {' '}
                <strong>30 minutes.</strong>
              </p>
            ),
          },
          {
            className: 'p-spec-item-w-25',
            label: 'Coverage',
            description: (
              <p>
                Max room coverage of
                {' '}
                <strong>585 sq ft</strong>
                {' '}
                and an optimal
                room coverage of
                {' '}
                <strong>234 sq ft.</strong>
              </p>
            ),
          },
          {
            className: 'p-spec-item-w-25',
            label: 'Carbon Filter',
            description: (
              <p>
                <strong>1.25lbs (567g)</strong>
                {' '}
                of modified granular carbon.
              </p>
            ),
          },

          {
            className: 'p-spec-item-w-50',
            label: 'HEPA Filter',
            description: (
              <p>
                <strong>HEPA Filter</strong>
                {' '}
                with 99.6% efficiency.
              </p>
            ),
          },
          {
            className: 'p-spec-item-w-50',
            label: 'Awards',
            description: (
              <p>
                <strong>“Best Air Purifier for Mold”</strong>
                {' '}
                — Rolling Stone.
              </p>
            ),
          },
        ],
      },

      airMini: {
        gridOne: [
          {
            className: 'p-spec-item-w-25',
            label: 'Performance',
            description: (
              <p>
                The Overreactor is always ready for action with with a CADR of
                {' '}
                <strong>231 m³/hr.</strong>
              </p>
            ),
          },
          {
            className: 'p-spec-item-w-25',
            label: 'Superpowers',
            description: (
              <p>
                Conquers VOCs with a
                {' '}
                <strong>93%</strong>
                {' '}
                removal rate and
                proven to wipe out
                <strong>98.9%</strong>
                {' '}
                of viruses in under
                {' '}
                <strong>30 minutes.</strong>
              </p>
            ),
          },
          {
            className: 'p-spec-item-w-25',
            label: 'Coverage',
            description: (
              <p>
                Max room coverage of
                {' '}
                <strong>510 sq ft</strong>
                {' '}
                and an optimal
                room coverage of
                {' '}
                <strong>204 sq ft.</strong>
              </p>
            ),
          },
          {
            className: 'p-spec-item-w-25',
            label: 'Carbon Filter',
            description: (
              <p>
                <strong>0.43lb(195g)</strong>
                {' '}
                of modified granular carbon.
              </p>
            ),
          },

          {
            className: 'p-spec-item-w-50',
            label: 'HEPA Filter',
            description: (
              <p>
                H14 HEPA with
                {' '}
                <strong> 99.97%</strong>
                {' '}
                efficiency.
              </p>
            ),
          },
          {
            className: 'p-spec-item-w-50',
            label: 'Awards',
            description: (
              <p>
                <strong>“Best New Product of 2025”</strong>
                {' '}
                — Newsweek Magazine.
              </p>
            ),
          },
        ],
      },
    },
  },
};

export default filterSpecsData;
