import React from 'react';

import filterBB from 'assets/images/performanceRefresh/filterProductStats/filter-BB.png';
import filterBS from 'assets/images/performanceRefresh/filterProductStats/filter-BS.png';
import filterCC from 'assets/images/performanceRefresh/filterProductStats/filter-CC.png';
import filterRP from 'assets/images/performanceRefresh/filterProductStats/filter-RP.png';
import filterHW from 'assets/images/performanceRefresh/filterProductStats/filter-HW.png';
import filterMB from 'assets/images/performanceRefresh/filterProductStats/filter-MB.png';
import filterOR from 'assets/images/performanceRefresh/filterProductStats/filter-OR.png';

const productStats = {
  air: [
    {
      label: 'CADR',
      stats: {
        BB: {
          value: '411',
          isBold: true,
        },
        BS: {
          value: '354',
          isBold: true,
        },
        CC: {
          value: '269',
          isBold: false,
        },
        RP: {
          value: '293',
          isBold: true,
        },
        HW: {
          value: '205',
          isBold: false,
        },
        MB: {
          value: '207',
          isBold: false,
        },
        OR: {
          value: '203',
          isBold: false,
        },
      },
    },
    {
      label: 'TVOC REMOVAL rate',
      stats: {
        BB: {
          value: 'N/A',
          isBold: false,
        },
        BS: {
          value: 'N/A',
          isBold: false,
        },
        CC: {
          value: '83.87%',
          isBold: true,
        },
        RP: {
          value: '69.63%',
          isBold: false,
        },
        HW: {
          value: '90.15%',
          isBold: true,
        },
        MB: {
          value: '85.12%',
          isBold: true,
        },
        OR: {
          value: 'Tests in progress',
          isBold: false,
        },
      },
    },
    {
      label: 'FORMALDEHYDE',
      stats: {
        BB: {
          value: 'N/A',
          isBold: false,
        },
        BS: {
          value: 'N/A',
          isBold: false,
        },
        CC: {
          value: '23.60%',
          isBold: false,
        },
        RP: {
          value: '26.75%',
          isBold: false,
        },
        HW: {
          value: '90.30%',
          isBold: true,
        },
        MB: {
          value: '21.57%',
          isBold: false,
        },
        OR: {
          value: 'Tests in progress',
          isBold: false,
        },
      },
    },
    {
      label: 'AMMONIA',
      stats: {
        BB: {
          value: 'N/A',
          isBold: false,
        },
        BS: {
          value: 'N/A',
          isBold: false,
        },
        CC: {
          value: '90.52%',
          isBold: true,
        },
        RP: {
          value: '21.52%',
          isBold: false,
        },
        HW: {
          value: '31.20%',
          isBold: false,
        },
        MB: {
          value: '18.90%',
          isBold: false,
        },
        OR: {
          value: 'Tests in progress',
          isBold: false,
        },
      },
    },
    {
      label: 'BENZENE',
      stats: {
        BB: {
          value: 'N/A',
          isBold: false,
        },
        BS: {
          value: 'N/A',
          isBold: false,
        },
        CC: {
          value: '72.73%',
          isBold: false,
        },
        RP: {
          value: '66.10%',
          isBold: false,
        },
        HW: {
          value: '88.33%',
          isBold: true,
        },
        MB: {
          value: '',
          isBold: false,
        },
        OR: {
          value: 'Tests in progress',
          isBold: false,
        },
      },
    },
    {
      label: 'TOLUENE',
      stats: {
        BB: {
          value: 'N/A',
          isBold: false,
        },
        BS: {
          value: 'N/A',
          isBold: false,
        },
        CC: {
          value: '84.51%',
          isBold: true,
        },
        RP: {
          value: '69.51%',
          isBold: false,
        },
        HW: {
          value: '92.05%',
          isBold: true,
        },
        MB: {
          value: '88.50%',
          isBold: true,
        },
        OR: {
          value: 'Tests in progress',
          isBold: false,
        },
      },
    },
    {
      label: 'BUTYL ACETATE',
      stats: {
        BB: {
          value: 'N/A',
          isBold: false,
        },
        BS: {
          value: 'N/A',
          isBold: false,
        },
        CC: {
          value: '85.88%',
          isBold: true,
        },
        RP: {
          value: '71.28%',
          isBold: false,
        },
        HW: {
          value: '90.96%',
          isBold: true,
        },
        MB: {
          value: '84.69%',
          isBold: true,
        },
        OR: {
          value: 'Tests in progress',
          isBold: false,
        },
      },
    },
    {
      label: 'N-UNDECANE',
      stats: {
        BB: {
          value: 'N/A',
          isBold: false,
        },
        BS: {
          value: 'N/A',
          isBold: false,
        },
        CC: {
          value: '85.33%',
          isBold: true,
        },
        RP: {
          value: '72.50%',
          isBold: false,
        },
        HW: {
          value: '91.23%',
          isBold: true,
        },
        MB: {
          value: '82.91%',
          isBold: true,
        },
        OR: {
          value: 'Tests in progress',
          isBold: false,
        },
      },
    },
    {
      label: 'ETHYLBENZENE',
      stats: {
        BB: {
          value: 'N/A',
          isBold: false,
        },
        BS: {
          value: 'N/A',
          isBold: false,
        },
        CC: {
          value: '85.90%',
          isBold: true,
        },
        RP: {
          value: '70.59%',
          isBold: false,
        },
        HW: {
          value: '91.04%',
          isBold: true,
        },
        MB: {
          value: '86.00%',
          isBold: true,
        },
        OR: {
          value: 'Tests in progress',
          isBold: true,
        },
      },
    },
    {
      label: 'P-XYLENEOU',
      stats: {
        BB: {
          value: 'N/A',
          isBold: false,
        },
        BS: {
          value: 'N/A',
          isBold: false,
        },
        CC: {
          value: '86.49%',
          isBold: true,
        },
        RP: {
          value: '70.00%',
          isBold: false,
        },
        HW: {
          value: '90.69%',
          isBold: true,
        },
        MB: {
          value: '85.35%',
          isBold: true,
        },
        OR: {
          value: 'Tests in progress',
          isBold: true,
        },
      },
    },
    {
      label: 'M-XYLENE',
      stats: {
        BB: {
          value: 'N/A',
          isBold: false,
        },
        BS: {
          value: 'N/A',
          isBold: false,
        },
        CC: {
          value: '85.90%',
          isBold: true,
        },
        RP: {
          value: '69.41%',
          isBold: false,
        },
        HW: {
          value: '90.65%',
          isBold: true,
        },
        MB: {
          value: '85.05%',
          isBold: true,
        },
        OR: {
          value: 'Tests in progress',
          isBold: true,
        },
      },
    },
    {
      label: 'O-XYLENE',
      stats: {
        BB: {
          value: 'N/A',
          isBold: false,
        },
        BS: {
          value: 'N/A',
          isBold: false,
        },
        CC: {
          value: '85.14%',
          isBold: true,
        },
        RP: {
          value: '67.95%',
          isBold: false,
        },
        HW: {
          value: '90.00%',
          isBold: true,
        },
        MB: {
          value: '84.52%',
          isBold: true,
        },
        OR: {
          value: 'Tests in progress',
          isBold: true,
        },
      },
    },
    {
      label: 'STYRENE',
      stats: {
        BB: {
          value: 'N/A',
          isBold: false,
        },
        BS: {
          value: 'N/A',
          isBold: false,
        },
        CC: {
          value: '81.97%',
          isBold: true,
        },
        RP: {
          value: '66.67%',
          isBold: false,
        },
        HW: {
          value: '84.92%',
          isBold: true,
        },
        MB: {
          value: '80.86%',
          isBold: true,
        },
        OR: {
          value: 'Tests in progress',
          isBold: true,
        },
      },
    },
  ],
  airMini: [
    {
      label: 'CADR',
      stats: {
        BB: {
          value: '512',
          isBold: true,
        },
        BS: {
          value: '455',
          isBold: true,
        },
        CC: {
          value: '368',
          isBold: false,
        },
        RP: {
          value: '394',
          isBold: true,
        },
        HW: {
          value: '306',
          isBold: false,
        },
        MB: {
          value: '108',
          isBold: false,
        },
        OR: {
          value: '104',
          isBold: false,
        },
      },
    },
    {
      label: 'TVOC REMOVAL rate',
      stats: {
        BB: {
          value: 'N/A',
          isBold: false,
        },
        BS: {
          value: 'N/A',
          isBold: false,
        },
        CC: {
          value: '83.87%',
          isBold: true,
        },
        RP: {
          value: '69.63%',
          isBold: false,
        },
        HW: {
          value: '90.15%',
          isBold: true,
        },
        MB: {
          value: '85.12%',
          isBold: true,
        },
        OR: {
          value: 'Tests in progress',
          isBold: false,
        },
      },
    },
    {
      label: 'FORMALDEHYDE',
      stats: {
        BB: {
          value: 'N/A',
          isBold: false,
        },
        BS: {
          value: 'N/A',
          isBold: false,
        },
        CC: {
          value: '23.60%',
          isBold: false,
        },
        RP: {
          value: '26.75%',
          isBold: false,
        },
        HW: {
          value: '90.30%',
          isBold: true,
        },
        MB: {
          value: '21.57%',
          isBold: false,
        },
        OR: {
          value: 'Tests in progress',
          isBold: false,
        },
      },
    },
    {
      label: 'AMMONIA',
      stats: {
        BB: {
          value: 'N/A',
          isBold: false,
        },
        BS: {
          value: 'N/A',
          isBold: false,
        },
        CC: {
          value: '90.52%',
          isBold: true,
        },
        RP: {
          value: '21.52%',
          isBold: false,
        },
        HW: {
          value: '31.20%',
          isBold: false,
        },
        MB: {
          value: '18.90%',
          isBold: false,
        },
        OR: {
          value: 'Tests in progress',
          isBold: false,
        },
      },
    },
    {
      label: 'BENZENE',
      stats: {
        BB: {
          value: 'N/A',
          isBold: false,
        },
        BS: {
          value: 'N/A',
          isBold: false,
        },
        CC: {
          value: '72.73%',
          isBold: false,
        },
        RP: {
          value: '66.10%',
          isBold: false,
        },
        HW: {
          value: '88.33%',
          isBold: true,
        },
        MB: {
          value: '',
          isBold: false,
        },
        OR: {
          value: 'Tests in progress',
          isBold: false,
        },
      },
    },
    {
      label: 'TOLUENE',
      stats: {
        BB: {
          value: 'N/A',
          isBold: false,
        },
        BS: {
          value: 'N/A',
          isBold: false,
        },
        CC: {
          value: '84.51%',
          isBold: true,
        },
        RP: {
          value: '69.51%',
          isBold: false,
        },
        HW: {
          value: '92.05%',
          isBold: true,
        },
        MB: {
          value: '88.50%',
          isBold: true,
        },
        OR: {
          value: 'Tests in progress',
          isBold: false,
        },
      },
    },
    {
      label: 'BUTYL ACETATE',
      stats: {
        BB: {
          value: 'N/A',
          isBold: false,
        },
        BS: {
          value: 'N/A',
          isBold: false,
        },
        CC: {
          value: '85.88%',
          isBold: true,
        },
        RP: {
          value: '71.28%',
          isBold: false,
        },
        HW: {
          value: '90.96%',
          isBold: true,
        },
        MB: {
          value: '84.69%',
          isBold: true,
        },
        OR: {
          value: 'Tests in progress',
          isBold: false,
        },
      },
    },
    {
      label: 'N-UNDECANE',
      stats: {
        BB: {
          value: 'N/A',
          isBold: false,
        },
        BS: {
          value: 'N/A',
          isBold: false,
        },
        CC: {
          value: '85.33%',
          isBold: true,
        },
        RP: {
          value: '72.50%',
          isBold: false,
        },
        HW: {
          value: '91.23%',
          isBold: true,
        },
        MB: {
          value: '82.91%',
          isBold: true,
        },
        OR: {
          value: 'Tests in progress',
          isBold: false,
        },
      },
    },
    {
      label: 'ETHYLBENZENE',
      stats: {
        BB: {
          value: 'N/A',
          isBold: false,
        },
        BS: {
          value: 'N/A',
          isBold: false,
        },
        CC: {
          value: '85.90%',
          isBold: true,
        },
        RP: {
          value: '70.59%',
          isBold: false,
        },
        HW: {
          value: '91.04%',
          isBold: true,
        },
        MB: {
          value: '86.00%',
          isBold: true,
        },
        OR: {
          value: 'Tests in progress',
          isBold: true,
        },
      },
    },
    {
      label: 'P-XYLENEOU',
      stats: {
        BB: {
          value: 'N/A',
          isBold: false,
        },
        BS: {
          value: 'N/A',
          isBold: false,
        },
        CC: {
          value: '86.49%',
          isBold: true,
        },
        RP: {
          value: '70.00%',
          isBold: false,
        },
        HW: {
          value: '90.69%',
          isBold: true,
        },
        MB: {
          value: '85.35%',
          isBold: true,
        },
        OR: {
          value: 'Tests in progress',
          isBold: true,
        },
      },
    },
    {
      label: 'M-XYLENE',
      stats: {
        BB: {
          value: 'N/A',
          isBold: false,
        },
        BS: {
          value: 'N/A',
          isBold: false,
        },
        CC: {
          value: '85.90%',
          isBold: true,
        },
        RP: {
          value: '69.41%',
          isBold: false,
        },
        HW: {
          value: '90.65%',
          isBold: true,
        },
        MB: {
          value: '85.05%',
          isBold: true,
        },
        OR: {
          value: 'Tests in progress',
          isBold: true,
        },
      },
    },
    {
      label: 'O-XYLENE',
      stats: {
        BB: {
          value: 'N/A',
          isBold: false,
        },
        BS: {
          value: 'N/A',
          isBold: false,
        },
        CC: {
          value: '85.14%',
          isBold: true,
        },
        RP: {
          value: '67.95%',
          isBold: false,
        },
        HW: {
          value: '90.00%',
          isBold: true,
        },
        MB: {
          value: '84.52%',
          isBold: true,
        },
        OR: {
          value: 'Tests in progress',
          isBold: true,
        },
      },
    },
    {
      label: 'STYRENE',
      stats: {
        BB: {
          value: 'N/A',
          isBold: false,
        },
        BS: {
          value: 'N/A',
          isBold: false,
        },
        CC: {
          value: '81.97%',
          isBold: true,
        },
        RP: {
          value: '66.67%',
          isBold: false,
        },
        HW: {
          value: '84.92%',
          isBold: true,
        },
        MB: {
          value: '80.86%',
          isBold: true,
        },
        OR: {
          value: 'Tests in progress',
          isBold: true,
        },
      },
    },
  ],
};

const productStatsResponsive = {
  image: {
    BB: filterBB,
    BS: filterBS,
    CC: filterCC,
    RP: filterRP,
    HW: filterHW,
    MB: filterMB,
    OR: filterOR,
  },

  description: {
    BB: 'For the everyday lover of clean air',
    BS: 'Helping you fight back against allergens',
    CC: 'Making the air clean and safe for your littles',
    RP: 'Clearing the air for fur families',
    HW: 'Protection from ALL the things',
    MB: 'Protection from ALL the things',
    OR: 'Extra protection when you’re breathing for two',
  },

  air: [
    {
      label: <>CH₂O</>,
      type: '% Removal',
      stats: {
        BB: {
          value: '447',
          isBold: true,
        },
        BS: {
          value: '416',
          isBold: true,
        },
        RP: {
          value: '264',
          isBold: true,
        },
        CC: {
          value: '350',
          isBold: false,
        },

        HW: {
          value: '300',
          isBold: false,
        },
        OR: {
          value: '245',
          isBold: false,
        },
        MB: {
          value: '154',
          isBold: false,
        },
      },
    },
    {
      label: <>AMMONIA</>,
      type: '% Removal',
      stats: {
        BB: {
          value: '747',
          isBold: true,
        },
        BS: {
          value: '416',
          isBold: true,
        },
        RP: {
          value: '364',
          isBold: true,
        },
        CC: {
          value: '350',
          isBold: false,
        },

        HW: {
          value: '300',
          isBold: false,
        },
        OR: {
          value: '245',
          isBold: false,
        },
        MB: {
          value: '254',
          isBold: false,
        },
      },
    },
    {
      label: <>TVOC</>,
      type: '% Removal',
      stats: {
        BB: {
          value: '447',
          isBold: true,
        },
        BS: {
          value: '416',
          isBold: true,
        },
        RP: {
          value: '364',
          isBold: true,
        },
        CC: {
          value: '350',
          isBold: false,
        },

        HW: {
          value: '300',
          isBold: false,
        },
        OR: {
          value: '245',
          isBold: false,
        },
        MB: {
          value: '254',
          isBold: false,
        },
      },
    },
    {
      label: <>CADR</>,
      type: '(m³/hr)',
      stats: {
        BB: {
          value: '447',
          isBold: true,
        },
        BS: {
          value: '416',
          isBold: true,
        },
        RP: {
          value: '364',
          isBold: true,
        },
        CC: {
          value: '350',
          isBold: false,
        },

        HW: {
          value: '300',
          isBold: false,
        },
        OR: {
          value: '245',
          isBold: false,
        },
        MB: {
          value: '254',
          isBold: false,
        },
      },
    },
    {
      label: <>CH₂O</>,
      type: '% Removal',
      stats: {
        BB: {
          value: '447',
          isBold: true,
        },
        BS: {
          value: '416',
          isBold: true,
        },
        RP: {
          value: '364',
          isBold: true,
        },
        CC: {
          value: '350',
          isBold: false,
        },

        HW: {
          value: '300',
          isBold: false,
        },
        OR: {
          value: '245',
          isBold: false,
        },
        MB: {
          value: '254',
          isBold: false,
        },
      },
    },
  ],
  airMini: [
    {
      label: <>CH₂O</>,
      type: '% Removal',
      stats: {
        BB: {
          value: '447',
          isBold: true,
        },
        BS: {
          value: '416',
          isBold: true,
        },
        RP: {
          value: '364',
          isBold: true,
        },
        CC: {
          value: '350',
          isBold: false,
        },

        HW: {
          value: '300',
          isBold: false,
        },
        OR: {
          value: '245',
          isBold: false,
        },
        MB: {
          value: '254',
          isBold: false,
        },
      },
    },
    {
      label: <>AMMONIA</>,
      type: '% Removal',
      stats: {
        BB: {
          value: '447',
          isBold: true,
        },
        BS: {
          value: '416',
          isBold: true,
        },
        RP: {
          value: '264',
          isBold: true,
        },
        CC: {
          value: '350',
          isBold: false,
        },

        HW: {
          value: '300',
          isBold: false,
        },
        OR: {
          value: '245',
          isBold: false,
        },
        MB: {
          value: '254',
          isBold: false,
        },
      },
    },
    {
      label: <>TVOC</>,
      type: '% Removal',
      stats: {
        BB: {
          value: '547',
          isBold: true,
        },
        BS: {
          value: '416',
          isBold: true,
        },
        RP: {
          value: '364',
          isBold: true,
        },
        CC: {
          value: '350',
          isBold: false,
        },

        HW: {
          value: '300',
          isBold: false,
        },
        OR: {
          value: '245',
          isBold: false,
        },
        MB: {
          value: '254',
          isBold: false,
        },
      },
    },
    {
      label: <>CADR</>,
      type: '(m³/hr)',
      stats: {
        BB: {
          value: '447',
          isBold: true,
        },
        BS: {
          value: '416',
          isBold: true,
        },
        RP: {
          value: '364',
          isBold: true,
        },
        CC: {
          value: '350',
          isBold: false,
        },

        HW: {
          value: '300',
          isBold: false,
        },
        OR: {
          value: '245',
          isBold: false,
        },
        MB: {
          value: '254',
          isBold: false,
        },
      },
    },
    {
      label: <>CH₂O</>,
      type: '% Removal',
      stats: {
        BB: {
          value: '447',
          isBold: true,
        },
        BS: {
          value: '416',
          isBold: true,
        },
        RP: {
          value: '364',
          isBold: true,
        },
        CC: {
          value: '350',
          isBold: false,
        },

        HW: {
          value: '300',
          isBold: false,
        },
        OR: {
          value: '245',
          isBold: false,
        },
        MB: {
          value: '254',
          isBold: false,
        },
      },
    },
  ],
};

export { productStats, productStatsResponsive };
