import React from 'react';
import video1 from '../../../assets/images/performanceRefresh/FilterReplacement/mila-filter-video-1.mp4';
import video2 from '../../../assets/images/performanceRefresh/FilterReplacement/mila-filter-video-2.mp4';
import video3 from '../../../assets/images/performanceRefresh/FilterReplacement/mila-filter-video-3.mp4';

const FilterReplacementStatic = {
  title: 'With Auto-Refill, filter changes are a breeze.',
  description: (
    <p>
      Get a fresh filter delivered every 6 months.
      {' '}
      <br />
      Pop out the old, slide in the
      new, and you’re set.
    </p>
  ),

  features: [
    {
      video: video1,
      title: 'Fast, convenient delivery',
      description: (
        <p>
          With Auto-Refill, you&apos;ll never miss a filter change. A fresh one
          shows up at your door every 6 months, shipped free.
        </p>
      ),
    },
    {
      video: video2,
      title: 'No crazy commitments',
      description: (
        <p>
          Have a scheduling change? No locked-in contracts here. Skip or cancel
          a delivery any time.
        </p>
      ),
    },
    {
      video: video3,
      title: 'Even longer warranty',
      description: (
        <p>
          As a filter subscriber, you&apos;ll get an extra 3 years of warranty
          coverage. (Yep—it&apos;s a pretty sweet deal.) 😊
        </p>
      ),
    },
  ],
};

export default FilterReplacementStatic;
