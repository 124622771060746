import React from 'react';

const filterFaqsStatic = {
  title: (
    <>
      FAQ for all you
      {' '}
      <br />
      geeks out there.
    </>
  ),
  faqs: [
    {
      question: 'Why Mini over Air ',
      answer: (
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam,
          quos.
        </p>
      ),
    },
    {
      question: 'Why Air over Mini',
      answer: (
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam,
          quos.
        </p>
      ),
    },
  ],
};
export default filterFaqsStatic;
