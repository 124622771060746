/* eslint-disable react/jsx-filename-extension */
import React from 'react';

const airAppChangeLogStatic = [
  {
    version: '2.4.0',
    time: null,
    prettyTime: null,
    description: (
      <>
        <p>
          <strong>Improvements and bug fixes: </strong>
        </p>
        <ul>
          <li>
            Touchscreen Improvements: We’ve improved touch sensitivity and fixed
            an issue where the touchscreen sometimes failed to initialize on
            bootup.
          </li>
          <li>
            Adjusted Alert Thresholds: Updates to VOC, CO2, humidity, and mold
            thresholds to make alerts more actionable and reduce false
            positives.
          </li>
        </ul>
      </>
    ),
  },
  {
    version: '2.3.1',
    time: null,
    prettyTime: null,
    description: (
      <>
        <p>
          <strong>New Features and Improvements:</strong>
        </p>
        <ul>
          <li>Improved sensor alert thresholds</li>
        </ul>
      </>
    ),
  },
  {
    version: '2.3.0',
    time: null,
    prettyTime: null,
    description: (
      <>
        <p>
          <strong>What’s New:</strong>
        </p>
        <ul>
          <li>
            <strong>Holiday Easter Eggs 😏:</strong>
            {' '}
            Keep an eye out for some
            seasonal cheer and festive surprises
          </li>
          <li>
            <strong> Brightness Tweaks:</strong>
            {' '}
            Daytime brightness is now set
            to 100%, while nighttime dims to a cozy 30%.
          </li>
          <li>
            <strong>Boot-Up Chime Update:</strong>
            {' '}
            Mila stays silent during
            Sleep or Whitenoise Mode, ensuring no disruptions during quiet
            hours.
          </li>
          <li>
            <strong>Display Improvements:</strong>
            {' '}
            Experience more responsive
            touch interactions
          </li>
        </ul>
      </>
    ),
  },
  {
    version: '2.2.0',
    time: null,
    prettyTime: null,
    description: (
      <>
        <p>
          <strong>Bug Fixes</strong>
        </p>
        <ul>
          <li>
            Fixed a time synchronization issue that could cause Mila’s schedules
            to fall out of sync if it couldn’t connect to the Internet
            immediately after restarting.
          </li>
          <li>
            Fixed random crashes that some users experienced due to HomeKit
            interactions on certain networks.
          </li>
        </ul>
      </>
    ),
  },
];

export default airAppChangeLogStatic;
